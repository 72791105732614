import style from './style.module.scss';
import { Pagination } from '../pagination/pagination';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';

interface CatalogContentProps {
    children: JSX.Element,
    handleChangePage: (page: number) => void,
    currentPage: number | undefined,
    pageCount: number
}

export const CatalogContent = ({ children, currentPage, handleChangePage, pageCount }: CatalogContentProps) => {
	return (
		<div className={ style.catalogContent }>
			{ children }
			<ToggleVisibility visible={ pageCount > 0 }>
				<Pagination className={ style.pagination } onChange={ handleChangePage } currentPage={ currentPage } pageCount={ pageCount } defaultPage={ 1 } />
			</ToggleVisibility>
		</div>
	);
};
