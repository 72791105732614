import { request } from '../../request';
import { IValue } from '../../../../components/select/select';

interface ILastItemsParams {
  count: number
}

interface IItemsParams {
    mode: number | undefined;
    pFrom: number | undefined;
    offset: number;
    size: number | number[] | string | string[] | null | undefined;
    sex: string | string[] | number | number[] | null | undefined;
    count: number;
    pTo: number | undefined;
    category: number | undefined;
    brand: number | number[] | string | string[] | null | undefined,
    new: number
}

export const lastItemsFetch = (query: ILastItemsParams) => {
	return request({ url: '/shop/last', method: 'GET', params: query });
};

export const itemsFetch = (query: IItemsParams) => {
	return request({ url: '/shop/items', method: 'GET', params: query });
};

export const itemFetch = (query: { id: number | string | undefined }) => {
	return request({ url: '/shop/item', method: 'GET', params: query });
};
