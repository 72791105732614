import { Typography } from '../typography/typography';
import cs from 'classnames';
import style from './style.module.scss';

interface ErrorProps {
  children: JSX.Element | JSX.Element[] | string,
  className?: string
}

export const Error = ({ children, className }: ErrorProps): JSX.Element => {
	return (
	  <div className={ cs(style.errorBlock, className) }>
			<svg className={ style.icon } viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M8 12L6 7H16L18 12L16 17H6L8 12ZM8.95407 15L10.1541 12L8.95407 9H14.6459L15.8459 12L14.6459 15H8.95407Z"/>
			</svg>
			<Typography type='body' className={ cs(style.error) }>{ children }</Typography>
		</div>
	);
};

Error.defaultProps = {
	className: null
};
