import style from '../../header/desktop/style.module.scss';
import globalStyle from '../styles/style.module.scss';
import { Input } from '../../input/input';
import { CategoriesGroup } from '../../categoriesGroup/categoriesGroup';
import { ToggleVisibility } from '../../toggleVisibility/toggleVisibility';
import { Tabs } from '../../tabs/tabs';
import { Tab } from '../../tab/tab';
import { Nav } from '../../navigation/nav';
import { SubcategoriesGroup } from '../../subcategoriesGroup/subcategoriesGroup';
import { Drawer } from '../../drawer/drawer';
import { useMenuNav } from '../hooks/useMenuNav';
import { NavLink } from '../../navLink/navLink';

interface MenuNavInterface {
  visible: boolean,
  onClose: () => void
}

export const MenuNavDesktop = ({ visible, onClose }: MenuNavInterface) => {
	const { setCategoryID, search, chosenCategoryID, categories } = useMenuNav();

	return (
		<Drawer visible={ visible } onClose={ onClose } position='left'>
			<div className={ globalStyle.inputBox }>
				<Input { ...search.handlers } className={ globalStyle.search } type='text' placeholder='Поиск по категориям' />
			</div>
			<Nav orientation='vertical' className={ globalStyle.menu }>
				<NavLink to='/items' className={ globalStyle.menuText } onClick={ onClose } label='Все товары'/>
				<NavLink to='/items?sex=1' className={ globalStyle.menuText } onClick={ onClose } label='Мужское'/>
				<NavLink to='/items?sex=2' className={ globalStyle.menuText } onClick={ onClose } label='Женское'/>
				<NavLink to='/items?sex=3' className={ globalStyle.menuText } onClick={ onClose } label='Унисекс'/>
				<NavLink to='/items?date=1' className={ globalStyle.menuText } onClick={ onClose } label='Новинки'/>
			</Nav>
			<CategoriesGroup chosenID={ chosenCategoryID } search={ search.value() } onMouseOver={ setCategoryID } onClick={ onClose } />
			<ToggleVisibility visible={ chosenCategoryID != null }>
				<div className={ style.categoryMenu }>
					<Tabs currentValue={ chosenCategoryID }>
						{ categories()?.map((category) => {
							const { id, subcategories } = category;

							return (
								<Tab key={ id } value={ id }>
									<Nav key={ id } orientation='horizontal'>
										<SubcategoriesGroup subcategories={ subcategories } onLinkClick={ onClose } linkClassName={ globalStyle.subcategoryLink } />
									</Nav>
								</Tab>
							);
						}) }
					</Tabs>
				</div>
			</ToggleVisibility>
		</Drawer>
	);
};
