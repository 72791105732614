import { useState, useLayoutEffect } from 'react';

export const useWindowSize = () => {
	const [windowScrollWidth, setScrollWindowWidth] = useState(0);
	const [windowScrollHeight, setScrollWindowHeight] = useState(0);

	useLayoutEffect(() => {
		const setSize = () => {
			setScrollWindowWidth(document.body.scrollWidth);
			setScrollWindowHeight(document.body.scrollHeight);
		};

		setSize();

		window.addEventListener('resize', setSize);

		return () => {
			window.removeEventListener('resize', setSize);
		};
	}, []);

	return {
	  width: windowScrollWidth,
		height: windowScrollHeight
	};
};
