import style from './style.module.scss';
import cs from 'classnames';
import { Button } from '../button/button';

interface IField {
  title: string,
  label: string | number
}

interface IButton extends IField {
  action: () => void
}

interface TableProps {
  columns: string[],
  data: DataRaw[]
}

interface DataRaw {
  id: number | string,
  data: IField[] | IButton[]
}

export const Table = ({ columns, data }: TableProps): JSX.Element => {
	return (
		<table className={ style.table }>
			<thead className={ style.thead }>
				<tr className={ style.tr }>
					{ columns?.map((label, index) => {
						return <th key={ index } className={ cs(style.tableField, style.th) } scope="col">{ label }</th>;
					}) }
				</tr>
			</thead>
			<tbody>
				{ data?.map((dataRaw: DataRaw) => {
				  const { id } = dataRaw;

					return (
						<tr key={ id } className={ style.tr }>
							{ dataRaw?.data?.map((raw: IButton | IField, index: number) => {
								const { label, title } = raw;

								if ('action' in raw) {
									const { action } = raw;

									return <td key={ index } className={ cs(style.tableField, style.td) } data-label={ title }><Button className={ style.button } onClick={ action } >{ label }</Button></td>;
								}

								return <td key={ index } className={ cs(style.tableField, style.td) } data-label={ title }>{ label }</td>;
							}) }
						</tr>
					);
				}) }
			</tbody>
		</table>
	);
};
