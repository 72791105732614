import style from '../../pages/items/style.module.scss';
import { CatalogContent } from '../catalogContent/catalogContent';

interface CatalogProps {
    filter?: JSX.Element,
    content?: JSX.Element,
    handleChangePage: (page: number) => void,
    currentPage: number | undefined,
    pageCount: number
}

export const Catalog = ({ filter, content, currentPage, handleChangePage, pageCount }: CatalogProps) => {
	return (
		<div className={ style.items }>
			{ filter }
			<CatalogContent handleChangePage={ handleChangePage } currentPage={ currentPage } pageCount={ pageCount }>
				<>{ content }</>
			</CatalogContent>
		</div>
	);
};

Catalog.defaultProps = {
	filter: undefined,
	content: undefined
};
