import style from './style.module.scss';
import cs from 'classnames';
import { Typography } from '../typography/typography';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';

interface BadgeProps {
  children: JSX.Element | JSX.Element[],
  text: string | number | undefined,
  className?: string
}

export const Badge = ({ text, children, className }: BadgeProps): JSX.Element => {
	return (
		<div className={ cs(style.badge, className) }>
			<ToggleVisibility visible={ !!text }>
				<div className={ style.badgeLabel }>
					<Typography type='subtitle2'>{
						text && text.toString().length > 2 ? text.toString().slice(0, 1) + '..' : text
					}</Typography>
				</div>
			</ToggleVisibility>
			{ children }
		</div>
	);
};

Badge.defaultProps = {
	className: null
};
