import { ReviewGroup } from '../../components/reviewGroup/reviewGroup';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { reviewsCount } from '../../recoil/atoms';
import { reviewsFetch } from '../../lib/api/rest/reviews';
import { useFetchStatus } from '../../lib/utils/hooks/useFetchStatus';
import { FetchStatus } from '../../lib/enums/fetchStatuses';
import { FetchResponse } from '../../components/fetchStatus/fetchStatus';

interface ReviewsAPIProps {
  className?: string,
  page: number | undefined,
  count: number
}

export const ReviewsContainer = ({ className, page, count }: ReviewsAPIProps): JSX.Element => {
	const [_, setCountReviews] = useRecoilState(reviewsCount);
	const [reviews, setReviews] = useState([]);
	const [fetchStatus, setFetchStatus] = useFetchStatus();

	useEffect(() => {
		reviewsFetch({
			count,
			offset: ((page as number) - 1) * count
		}).then((res) => {
			const { reviews, count } = res.data;

			setFetchStatus(reviews ? FetchStatus.SUCCESS : FetchStatus.NOT_FOUND);

			setReviews(reviews);
			setCountReviews(count);
		}).catch(() => setFetchStatus(FetchStatus.NOT_FOUND));
	}, [page]);

	return (
	  <FetchResponse status={ fetchStatus } error='К сожалению, комментариев пока нет, но вы можете стать первым!'>
			<ReviewGroup className={ className } reviews={ reviews } />
		</FetchResponse>
	);
};

ReviewsContainer.defaultProps = {
	className: null,
	page: 0,
	count: 10
};
