import { StyleHTMLAttributes } from 'react';
import cs from 'classnames';
import style from './style.module.scss';
import { ItemCard } from '../itemCard/itemCard';

interface IItem {
    id: number,
    name: string,
    image: string,
    price: number,
    origin_price: number
}

interface ItemGroupProps {
  className?: string,
  style?: StyleHTMLAttributes<any>,
  items: IItem[]
}

export const ItemsGroup = ({ className, style: styles, items }: ItemGroupProps): JSX.Element => {
	return (
		<div style={ styles } className={ cs(style.itemGroup, className) }>
			{ items?.map(({ id, image, name: title, origin_price }) => {
			  return <ItemCard className={ style.card } itemID={ id } key={ id } title={ title } price={ origin_price + ' ₽' } image={ image } />;
			}) }
		</div>
	);
};

ItemsGroup.defaultProps = {
	className: null,
	style: null
};
