import React, { forwardRef, StyleHTMLAttributes, ReactElement, MutableRefObject } from 'react';
import { ISlide } from '../slide/slide';
import style from './style.module.scss';

interface ISliderTrack {
  children: ReactElement<ISlide> | ReactElement<ISlide>[] | undefined;
  position: 'vertical' | 'horizontal';
  transform: number;
}

export const SliderTrack = forwardRef(
	({ children, position, transform }: ISliderTrack, ref) => {
		const trackStyle = {
			transform: `translate${
				position === 'vertical' ? 'Y' : 'X'
			}(-${transform}px)`,
			flexDirection: position === 'horizontal' ? 'row' : 'column'
		};

		return (
			<div ref={ ref as MutableRefObject<HTMLDivElement> } data-position={ position } className={ style.sliderTrack } style={ trackStyle as StyleHTMLAttributes<HTMLDivElement> }>
				{ children }
			</div>
		);
	}
);

SliderTrack.displayName = 'SliderTrack';
