import { useRecoilState } from 'recoil';
import { isMobileAtom } from '../../recoil/atoms';
import { FooterMobile } from './mobile/footerMobile';
import { FooterDesktop } from './desktop/footer';

export const Footer = (): JSX.Element => {
	const [isMobile] = useRecoilState(isMobileAtom);

	if (isMobile) return <FooterMobile />;

	return <FooterDesktop />;
};
