import { selector } from 'recoil';
import { itemCategoriesAtom, itemSizesAtom, userBasketAtom } from './atoms';
import { SizeObject, Sizes } from '../lib/enums/responses';
import { IValue } from '../components/select/select';
import memoize from 'lodash.memoize';

export const sizeSelector = selector({
	key: 'sizeSelector',
	get: ({ get }) => {
		const sizes = get(itemSizesAtom);

		const sizeByID = (id: IValue | undefined) => {
			const sizesParams: SizeObject[] = sizes?.map((sizes: Sizes) => sizes.params).flat();
			return sizesParams?.find((size: SizeObject) => size.sizeID === id);
		};

		return { sizeByID };
	}
});

export const basketSelector = selector({
	key: 'basketSelector',
	get: ({ get }) => {
	  	const basket = get(userBasketAtom);

	  	// Полная сумма
		const totalPrice = basket?.reduce((acc, cur) => acc + (cur.count * cur.price), 0) ?? 0;
		const totalOriginPrice = basket?.reduce((acc, cur) => acc + (cur.count * cur.origin_price), 0) ?? 0;

		const discount = (): { type?: number, sum: number } | undefined => {
			if (totalPrice >= 10000) {
				return { type: 1, sum: Math.ceil(totalPrice * 0.98) };
			} else if (totalPrice >= 20000) {
				return { type: 2, sum: Math.ceil(totalPrice * 0.96) };
			} else if (totalPrice >= 30000) {
				return { type: 3, sum: Math.ceil(totalPrice * 0.94) };
			} else if (totalPrice >= 40000) {
				return { type: 4, sum: Math.ceil(totalOriginPrice) };
			}

			return { type: 0, sum: totalPrice };
		};
		// eslint-disable-next-line no-restricted-globals
		return { length, totalPrice, discount };
	}
});

export const categorySelector = selector({
	key: 'categorySelector',
	get: ({ get }) => {
		const categories = get(itemCategoriesAtom);

		const searchCategory = (search: string | undefined) => {
			return categories?.filter((category) => category.subcategories?.find((subcategory) => subcategory.text.toLowerCase().search(search?.toLowerCase() as string) !== -1));
		};

		const searchBySubcategory = memoize((search: string | undefined) => {
		  return search ? searchCategory(search)?.map((category) => {
				return ({
					...category,
					subcategories: category.subcategories?.filter((subcategory) => search && subcategory.text.toLowerCase().search(search.toLowerCase()) !== -1)
				});
			}) : categories;
		});

		const findSubcategoryByID = memoize((id: number | undefined) => {
		  	const subcategories = categories?.map((category) => category.subcategories).flat();

			return subcategories?.find((subcategory) => subcategory?.categoryID === id);
		});

		return { searchCategory, findSubcategoryByID, searchBySubcategory };
	}
});
