import { Typography } from '../../components/typography/typography';
import { Input } from '../../components/input/input';
import { Button } from '../../components/button/button';
import { Link } from '../../components/link/link';
import style from './style.module.scss';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { isLoggedAtom } from '../../recoil/atoms';
import { useAuth } from '../../lib/utils/hooks/useAuth';
import { RegExp } from '../../lib/enums/regExp';
import { useForm } from 'react-awesome-form';
import { signIn } from '../../lib/api/';

export const Auth = (): JSX.Element => {
	const history = useHistory();
	const [, setLogged] = useRecoilState(isLoggedAtom);
	const authUser = useAuth();

	const { addInput, validate: validateForm, errors } = useForm();

	const email = addInput('email', false, '', { required: true, regExp: RegExp.EMAIL });
	const password = addInput('password', false, '', { required: true });

	const handleSignIn = () => {
		validateForm();
		console.log(errors);
		if (errors.length > 0) return null;

		signIn({
			user: {
				email: email.value(),
				password: password.value(),
				fingerprint: window.navigator.userAgent
			}
		}).then((res: any) => {
			if (res.data) {
				const { user: { JWT } } = res.data;

				authUser(JWT).then(() => {
					setLogged(true);
					history.push('/profile');
				});
			}
		});
	};

	return (
		<main className={ style.auth }>
			<form className={ style.authForm } noValidate>
				<Typography type='h3' tag='h1'>Авторизация</Typography>
				<Input className={ style.field } { ...email.handlers } onBlur={ email.handlers.onBlur } error={ email.error } errorMsg='Пожалуйста, напишите правильную почту' type='text' placeholder='Email' />
				<Input className={ style.field } { ...password.handlers } error={ password.error } errorMsg='Поле обязательно' type='password' placeholder='Пароль' />
				<Button className={ style.button } theme='primary' onClick={ handleSignIn }>Войти</Button>
				<Link className={ style.link } href='/signUp' type='inner' theme='secondary' size='medium'>Регистрация</Link>
			</form>
		</main>
	);
};
