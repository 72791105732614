import style from './style.module.scss';
import React, { KeyboardEvent, MouseEventHandler } from 'react';
import cs from 'classnames';
import { isKeyboardPress } from '../../../lib/utils/isKeyboardPress';

interface DotsProps {
  count: number,
  onClick: (index: number) => void,
  activeIndex: number
}

export const Dots = ({ count, onClick, activeIndex }: DotsProps): JSX.Element => {
	const dots = Array.from({ length: count }, (_, index) => {
		const handleClick = () => onClick(index);

		const handleKeyDown = (e: KeyboardEvent) => isKeyboardPress(e) && onClick(index);

		return <div role='button' key={ index } onClick={ handleClick } onKeyDown={ handleKeyDown } tabIndex={ 0 } className={ cs(style.dot, { [style.active]: index === activeIndex }) }/>;
	});

	return (
		<div className={ style.dotsGroup }>
			{ dots }
		</div>
	);
};
