import style from './style.module.scss';
import cs from 'classnames';
import { Typography } from '../typography/typography';

interface PointProps {
  title: string,
  description: string,
  image?: string,
  className?: string
}

export const Point = ({ title, description, image, className }: PointProps): JSX.Element => {
	return (
		<div className={ cs(style.point, className) }>
			<div className={ style.textBlock }>
				<Typography type='h3' tag='span' className={ style.title }>{ title }</Typography>
				<Typography type='body' className={ style.description }>{ description }</Typography>
			</div>
			{ image &&
        <div className={ style.imageContainer }>
        	<img src={ image } className={ style.image } alt={ title } />
        </div>
			}
		</div>
	);
};

Point.defaultProps = {
	image: null,
	className: null
};
