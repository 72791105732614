import style from './style.module.scss';
import { Typography } from '../typography/typography';
import cs from 'classnames';

type Price = {
  label: string,
  price: string | null,
  highlight?: boolean
}

interface PriceBlock {
  price: Price[],
  className?: string
}

export const PriceBlock = ({ price, className }: PriceBlock): JSX.Element => {
	let priceID = 0;

	const priceLabels = price.map(({ label, price, highlight }) => {
	  priceID++;

	  if (!price) return null;

	  return (
			<li className={ style.priceOption } key={ priceID }>
				<Typography type='body' tag='span' className={ cs(style.priceTitle, { [style.highlight]: highlight }) }>{ label }</Typography>
				<Typography type='body' tag='span' className={ cs({ [style.highlight]: highlight }) }>{ price }</Typography>
			</li>
		);
	});

	return (
		<ul className={ cs(style.priceGroup, className) }>
			{ priceLabels }
		</ul>
	);
};

PriceBlock.defaultProps = {
	className: null
};
