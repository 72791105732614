import style from './style.module.scss';
import cs from 'classnames';
import { InputHTMLAttributes, useState } from 'react';
import { Error } from '../error/error';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';

interface InputProps extends InputHTMLAttributes<any> {
  className?: string,
  onInput?: (value?: any) => void,
  onBlur?: (value?: any) => void,
  error?: boolean,
  errorMsg?: string
}

export const Input = ({ className, type, onInput, placeholder, error, onBlur, errorMsg, value, onChange }: InputProps): JSX.Element => {
	const handleInput = onInput ? (e: any) => onInput(e) : undefined;
	const [isFocus, setFocusBool] = useState(false);

	const handleFocus = () => setFocusBool(true);

	const handleBlur = onBlur ? (e: any) => {
		onBlur(e);
		setFocusBool(false);
	} : undefined;

	if (errorMsg) {
		return (
		  <label className={ cs(className, style.label) }>
				<input onChange={ onChange } onFocus={ handleFocus } value={ value } onInput={ handleInput } onBlur={ handleBlur } className={ cs(style.input, style.field, className, { [style.error]: error } ) } type={ type } placeholder={ placeholder } />
				<ToggleVisibility visible={ !!error && isFocus }>
					<Error className={ style.fieldError }>{ errorMsg }</Error>
				</ToggleVisibility>
			</label>
		);
	}

	return (
		<input onChange={ onChange } value={ value } className={ cs(style.input, className, { [style.error]: error } ) } type={ type } onBlur={ handleBlur } onInput={ handleInput } placeholder={ placeholder } />
	);
};

Input.defaultProps = {
	className: null,
	onInput: null,
	error: false,
	onBlur: null,
	errorMsg: null,
	onChange: null
};
