import cs from 'classnames';

import { Slider } from '../../components/slider/slider/slider';
import { Slide } from '../../components/slider/slide/slide';
import { SlideContent } from '../../components/slideContent/slideContent';

import style from './style.module.scss';

import mainImage from '../../images/slider/image_main.png';
import secondImage from '../../images/slider/sonie-image.jpg';
import thirdImage from '../../images/slider/neon-image.jpg';
import fourthImage from '../../images/slider/image3.jpg';
import fifthImage from '../../images/slider/img_1.png';

import bannerImage from '../../images/banner/georgie-cobbs-yfgptSTq2SQ-unsplash-removebg (1) 1.png';

import { Typography } from '../../components/typography/typography';
import { Link } from '../../components/link/link';
import { Section } from '../../components/section/section';
import { GeoPicker } from '../../components/geoPicker/geoPicker';
import { Banner } from '../../components/banner/banner';
import { LastItemsContainer } from '../../containers/lastItemsContainer/lastItemsContainer';
import { ReactComponent as CatAndCatSVG } from '../../images/catandcat.svg';
import { ReactComponent as StoreSVG } from '../../images/store.svg';
import { LinkBlock } from '../../components/linkBlock/linkBlock';
import man from '../../images/categoryBlock/man.png';
import woman from '../../images/categoryBlock/woman.png';
import unisex from '../../images/categoryBlock/unisex.png';
import { Point } from '../../components/point/point';
import imgBox from '../../images/why/fMveBTz2qWw.png';
import imgShoe from '../../images/why/shoe.png';
import imgUnderground from '../../images/why/underground.png';

export const Home = (): JSX.Element => {
	return (
		<main className={ style.main }>
			<Slider className={ style.slider } dots arrows={ false } position='vertical' autoplay autoplayDuration={ 10000 }>
				<Slide className={ style.slide }>
					<SlideContent className={ style.slideContent } bgImage={ mainImage }
						subtitle={
							<Typography className={ style.subtitle } type='h3' tag='span'>
								Скидки от 10.000
							</Typography>
						}
						title={
							<Typography className={ cs(style.title, style.mainText) } type='bgTitle' tag='h3'>{ 'Супер \n Скидки на опт' }</Typography>
						}
						body={
							<>
								<Typography type='bgtTitle' className={ style.mainText } style={ { background: 'linear-gradient(90deg, #ff0844 0%, #ffb199 100%)' } } tag='h3' gradient>#СКИДКИ</Typography>
								<Link type='inner' href='/customers/1'>Подробнее</Link>
							</>
						}
					/>
				</Slide>
				<Slide className={ style.slide }>
					<SlideContent className={ style.slideContent } bgImage={ secondImage }
						subtitle={
							<Typography className={ style.subtitle } type='h3' tag='span'>Покупай от 1 единицы</Typography>
						}
						title={
							<Typography className={ cs(style.title, style.mainText) } type='bgTitle' tag='h3'>{ 'Покупай \n от 1 единицы' }</Typography>
						}
						body={
							<>
								<Typography type='bgtTitle' className={ style.mainText } style={ { background: 'linear-gradient(90deg, #fc6076 0%, #ff9a44 100%)' } } tag='h3' gradient>#ОТ 1 ТОВАРА</Typography>
								<Link type='inner' href='/customers'>Подробнее</Link>
							</>
						}
					/>
				</Slide>
				<Slide className={ style.slide }>
					<SlideContent className={ style.slideContent } bgImage={ thirdImage }
						subtitle={
							<Typography className={ style.subtitle } type='h3' tag='span'>Бесплатная доставка</Typography>
						}
						title={
							<Typography className={ cs(style.title, style.mainText) } type='bgTitle' tag='h3'>{ 'Доставка до любой ТК \n бесплатная' }</Typography>
						}
						body={
							<>
								<Typography type='bgtTitle' className={ style.mainText } style={ { background: 'linear-gradient(90deg, #ec77ab 0%, #7873f5 100%)' } } tag='h3' gradient>#до тк бесплатно</Typography>
								<Link type='inner' href='/customers'>Подробнее</Link>
							</>
						}
					/>
				</Slide>
				<Slide className={ style.slide }>
					<SlideContent className={ style.slideContent } bgImage={ fourthImage }
						  subtitle={
							  <Typography className={ style.subtitle } type='h3' tag='span'>Мега скидка</Typography>
						  }
						  title={
							  <Typography className={ cs(style.title, style.mainText) } type='bgTitle' tag='h3'>{ 'Минимальная цена \n при закупке от \n 40.000' }</Typography>
						  }
						  body={
							  <>
								  <Typography type='bgtTitle' className={ style.mainText } style={ { background: 'linear-gradient(90deg, #e9defa 0%, #fbfcdb 100%)' } } tag='h3' gradient>#СКИДКА БОЛЕЕ 6%</Typography>
								  <Link type='inner' href='/customers/1'>Подробнее</Link>
							  </>
						  }
					/>
				</Slide>
			</Slider>
			<Section title='Новое' className={ style.section }>
				<LastItemsContainer className={ style.itemsGroup } />
			</Section>
			<Section title='Магазины' className={ style.section }>
				<GeoPicker className={ style.geoPicker } geo={ [
					{
						mapURL: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2245.3003979936675!2d37.77268391580356!3d55.753283699422525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414acabb7c7ab663%3A0x93b576438f275f5c!2zMS3RjyDQktC70LDQtNC40LzQuNGA0YHQutCw0Y8g0YPQuy4sIDIw0JAsINCc0L7RgdC60LLQsCwgMTExMTQx!5e0!3m2!1sru!2sru!4v1618665974814!5m2!1sru!2sru',
						address: 'г. Москва, 1-я Владимирская 20 А',
						mobile: '+7 (926) 334-35-23',
						workTime: '10:00 - 22:00',
						image: CatAndCatSVG
					},
					{
						mapURL: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2283.692456761661!2d38.80127521590465!3d55.08363798037966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414a711cae18307f%3A0xa792242d1c57da14!2z0YPQuy4g0J7QutGC0Y_QsdGA0YzRgdC60L7QuSDQoNC10LLQvtC70Y7RhtC40LgsIDM2OCwg0JrQvtC70L7QvNC90LAsINCc0L7RgdC60L7QstGB0LrQsNGPINC-0LHQuy4sIDE0MDQwNg!5e0!3m2!1sru!2sru!4v1623090433421!5m2!1sru!2sru',
						address: 'г. Коломна ул. Октябрьской революции 368',
						mobile: '+7 (985) 546-04-64',
						workTime: '10:00 - 22:00',
						image: StoreSVG
					}
				] }
				/>
			</Section>
			<Section title='Раздел' className={ style.section }>
				<div className={ style.categoryGroup }>
					<LinkBlock className={ style.category } href='/items?sex=1' bgImage={ man } title='Мужское' />
					<LinkBlock className={ style.category } href='/items?sex=2' bgImage={ woman } title='Женское' />
					<LinkBlock className={ style.category } href='/items?sex=3' bgImage={ unisex } title='Унисекс' />
				</div>
			</Section>
			<Section title='Почему мы?' className={ style.section }>
				<Point className={ style.point } title='Быстрая доставка' description='Мы стараемся доставить товары максимально быстро, чтобы вам было приятно. Срок доставки 1 -3 дня.' image={ imgBox }/>
				<Point className={ style.point } title='Широкий ассортимент' description='Для того, чтобы вы смогли найти то, что вам необходимо, мы постоянно обновляем свой ассортимент.' image={ imgShoe }/>
				<Point className={ style.point } title='Доступность' description='Наши магазины находятся в шаговой доступности от метро.' image={ imgUnderground }/>
			</Section>
			<Banner buttonText='NEW 2021' bgColor='#DEF8FF' subtitle='Новые вещи - хорошее настроение' title='НОВИНКИ 2021' href='/items?date=1' description='Успей порадовать себя новыми покупками по доступным ценам' image={ bannerImage } />
		</main>
	);
};
