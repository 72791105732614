import { ItemsGroup } from '../../components/itemGroup/itemGroup';
import { useEffect, useState } from 'react';
import { searchFetch } from '../../lib/api/rest/search';
import { FetchResponse } from '../../components/fetchStatus/fetchStatus';
import { useFetchStatus } from '../../lib/utils/hooks/useFetchStatus';
import { FetchStatus } from '../../lib/enums/fetchStatuses';
import { useRecoilState } from 'recoil';
import { searchItemsCountAtom } from '../../recoil/atoms/searchItemsCount';

// import Jimp from 'jimp';

interface SearchContainerProps {
	search: string,
	count: number,
	page: number
}

export const SearchContainer = ({ search, count, page }: SearchContainerProps) => {
	const [items, setItems] = useState([]);
	const [fetchStatus, setFetchStatus] = useFetchStatus();
	const [, setSearchItemsCount] = useRecoilState(searchItemsCountAtom);
	let itemList: any[];


	// let getJimpImageByPath = async function (url?: string) {
	// 	let imageByHashPath;
	// 	if (url) {
	// 		imageByHashPath = url;
	// 	} else {
	// 		let storedImageByHashJson = JSON.parse(localStorage.getItem('imageByHash') || '{}');
	// 		imageByHashPath = storedImageByHashJson[search.split('=')[1]]
	// 		let result = await (await fetch(imageByHashPath)).arrayBuffer();
	// 		imageByHashPath = Buffer.from(result);
	// 	}
	// 	if (imageByHashPath) {
	// 		let jimpImage;
	// 		if (typeof imageByHashPath === 'string') {
	// 			jimpImage = await Jimp.read(imageByHashPath as string);
	// 		} else {
	// 			jimpImage = await Jimp.read(imageByHashPath as Buffer);
	// 		}
	// 		return jimpImage;
	// 	}
	// 	return null;
	// }

	let searchProcess = async function () {
		let searchRes = await searchFetch({ query: search, mode: 1, count, offset: (page - 1) * count });
		const { items } = searchRes?.data || {};
		const itemsCount = searchRes?.data.count || 0;
		// let filteredItems: never[] = [];
		// itemList = items;
		setSearchItemsCount(itemsCount);
		setFetchStatus(items ? FetchStatus.SUCCESS : FetchStatus.NOT_FOUND);
		// let targetImage = await getJimpImageByPath();
		// if (!targetImage) {
			return items;
		// }

		// for (let i = 0; i < items.length; i++) {
		// 	const item = items[i];
		// 	let checkItem = false;
		// 	if (!item.images || item.images.length < 1) {
		// 		continue;
		// 	}
		// 	for (let j = 0; j < item.images.length; j++) {
		// 		const imageUrl = item.images[j];
		// 		let itemImage = await getJimpImageByPath(imageUrl);
		// 		if (!itemImage) {
		// 			continue;
		// 		}
		// 		let diff = Jimp.diff(itemImage, targetImage!);
		// 		if (diff.percent > 0.7) {
		// 			checkItem = true;
		// 		}
		// 	}
		// 	if (checkItem) {
		// 		filteredItems.push(item as never);
		// 	}
		// }
		// return filteredItems;

	}

	useEffect(() => {

		search && searchProcess().then(items => {
			setItems(items);
		}).catch(() => setFetchStatus(FetchStatus.NOT_FOUND))



		// search && searchFetch({ query: search, mode: 1, count, offset: (page - 1) * count }).then((res) => {
		// 	const { items, count } = res?.data || {};
		// 	itemList = items;
		// 	setSearchItemsCount(count);
		// 	setFetchStatus(items ? FetchStatus.SUCCESS : FetchStatus.NOT_FOUND);
		// 	return items

		// }).then(items => {
		// 	itemList = items;

		// 	if (search.includes('hash')) {
		// 		return getImageByPath();
		// 	} else {
		// 		return null;
		// 	}
		// }).then(image => {
		// 	if (image) {
		// 		let filtredItemList: never[];
		// 		let promiseArr: Promise<any>[] = [];
		// 		itemList.forEach(item => {
		// 			if (item.images || item.images.length < 1) {
		// 				return;
		// 			}
		// 			item.images.forEach(imageUrl => {
		// 				let itemJimpImage;
		// 				let targetJimpImage;
		// 				let promise = new Promise((resolve, reject) => {
		// 					return fetch(imageUrl);
		// 				}).then(img => {
		// 					if (!img) {
		// 						return reject
		// 					}
		// 					return Jimp.read(img);
		// 				}).then(jimpImage => {

		// 				}).then(jimpImage => {
		// 					let diff = Jimp.diff(jimpImage, image);
		// 				}).catch(err => {
		// 					console.log(err);
		// 				});
		// 			});
		// 		});

		// 		Promise.all(promiseArr)
		// 	} else {
		// 		setItems(itemList as never[]);
		// 	}
		// }).then(targetJimpImage => {

		// }).catch(() => setFetchStatus(FetchStatus.NOT_FOUND));
	}, [search, page, count]);

	return (
		<FetchResponse status={fetchStatus} error='Мы не смогли найти ничего по вашему запросу'>
			<ItemsGroup items={items} />
		</FetchResponse>
	);
};