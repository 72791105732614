import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Home } from './pages/home/home';
import { Items } from './pages/items/items';
import { Item } from './pages/item/item';
import { Basket } from './pages/basket/basket';
import { Auth } from './pages/auth/auth';
import { Reg } from './pages/reg/reg';
import { Reviews } from './pages/reviews/reviews';
import { Ordering } from './pages/ordering/ordering';
import { Customers } from './pages/customers/customers';
import { CustomersItem } from './pages/customersItem/customersItem';
import { Profile } from './pages/profile/profile';
import { Order } from './pages/order/order';
import { PrivateRoute } from './components/privateRoute/privateRoute';
import { APIInit } from './containers/APIInit/APIInit';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { NotFound } from './pages/notFound/notFound';
import { ScrollToTop } from './components/scrollToTop/scrollToTop';
import { Delivery } from './pages/delivery/delivery';
import { Policy } from './pages/policy/policy';
import { PurchaseModal } from './components/purchaseModal/purchaseModal';
import { Admin } from './pages/admin/admin';
import { setStorage } from './lib/storage';
import { setBaseURL } from './lib/api';
import { Search } from './pages/search/search';

const Main = withRouter(({ location }: any): JSX.Element => {
	return (
		<RecoilRoot>
			<APIInit>
				<PurchaseModal />
				{ ['/'].includes(location.pathname) ? <Header /> : <Header dark/> }
				<ScrollToTop />
				<Switch>
					<Route exact path='/'>
						<Home />
					</Route>
					<Route path='/items/:categoryID?'>
						<Items />
					</Route>
					<Route path='/item/:id'>
						<Item />
					</Route>
					<Route path='/search/:search'>
						<Search />
					</Route>
					<Route path='/basket'>
						<Basket />
					</Route>
					<Route path='/signIn'>
						<Auth />
					</Route>
					<Route path='/signUp'>
						<Reg />
					</Route>
					<Route path='/reviews'>
						<Reviews />
					</Route>
					<Route path='/customers/:qualityID'>
						<CustomersItem />
					</Route>
					<Route path='/customers'>
						<Customers />
					</Route>
					<Route path='/ordering'>
						<Ordering />
					</Route>
					<Route path='/delivery'>
						<Delivery />
					</Route>
					<Route path='/policy'>
						<Policy />
					</Route>
					<PrivateRoute path='/profile'>
						<Profile />
					</PrivateRoute>
					<Route path='/order/:orderID'>
						<Order />
					</Route>
					<Route path='/admin'>
						<Admin />
					</Route>
					<Route path='*'>
						<NotFound />
					</Route>
				</Switch>
				<Footer />
			</APIInit>
		</RecoilRoot>
	);
});

const App = (): JSX.Element => {
	setStorage(localStorage);
	setBaseURL(process.env.REACT_APP_API_URL as string);

	return (
		<Router>
			<Main />
		</Router>
	);
};

export default App;
