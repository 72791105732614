import { request } from '../../request';

interface ReviewsFetchParams {
  count: number,
  offset: number,
}

interface Item {
  price: number;
  count: number
  id: number
  image: number
  size: number
  name: string,
  image_url: string
}

export interface IOrderAdminFetchResponse {
  created_at: string,
  customer: {
    city: string
    email: string
    flat: string
    house: string
    index: string
    phone: string
    street: string
  }
  delivery_time: string
  deliverymethod: number
  id: string
  ispayed: boolean
  items: Item[],
  method: number
  pickup_date: string
  region: number
  status: number
  totalprice: number
}

export const ordersAdminFetch = (query: ReviewsFetchParams) => {
	return request({ url: '/admin/orders', headers: { authorization: true }, params: query });
};
