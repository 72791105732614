import style from './style.module.scss';
import cs from 'classnames';

interface MapProps {
  mapURL: string,
  className?: string
}

export const Map = ({ mapURL, className }: MapProps): JSX.Element => {
	return <iframe title='1-ая Владимирская 20А, Москва' src={ mapURL } loading='lazy' className={ cs(style.map, className) }/>;
};

Map.defaultProps = {
	className: null
};
