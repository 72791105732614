import React, { ForwardedRef, forwardRef } from 'react';

export interface ISlide {
  children: Element | Element[] | string | number | any;
  onClick?: () => void;
  onKeyDown?: (e: any) => void;
  className?: string;
}

export const Slide = forwardRef(
	({ children, onClick, onKeyDown, className }: ISlide, ref) => {
		return (
			<div ref={ ref as ForwardedRef<any> } onClick={ onClick } onKeyDown={ onKeyDown } tabIndex={ onClick ? 0 : -1 } role='button' className={ className }>
				{ children }
			</div>
		);
	}
);

Slide.displayName = 'Slide';

Slide.defaultProps = {
	className: undefined
};
