import { request } from '../../request';

interface IOrderFetch {
    id: number
}

export const orderCreate = (data: any) => {
	return request({ url: '/order', method: 'POST', data, headers: { authorization: true } });
};

export const orderFetch = (params: IOrderFetch) => {
	return request({ url: '/order', method: 'GET', params });
};