import style from './style.module.scss';
import { Icon } from '../../icon/icon';
import { ReactComponent as GeoSVG } from '../../../images/geo.svg';
import { Typography } from '../../typography/typography';
import { ReactComponent as MailSVG } from '../../../images/mail.svg';
import { ReactComponent as MobileSVG } from '../../../images/mobile.svg';
import { ReactComponent as ViberSVG } from '../../../images/social/viber.svg';
import { ReactComponent as TelegramSVG } from '../../../images/social/telegram.svg';
import { ReactComponent as WhatsAppSVG } from '../../../images/social/whatsapp.svg';
import { Link } from '../../link/link';

export const FooterAbout = (): JSX.Element => {
	return (
		<>
			<div className={ style.textBlock }>
				<Icon image={ GeoSVG } size='small' />
				<Typography type='body' className={ style.text }>
          г. Москва,1-я Владимирская 20 А
				</Typography>
			</div>
			<div className={ style.textBlock }>
				<Icon image={ MailSVG } size='small' />
				<Typography type='body' className={ style.text }>
          n-o-k-i@bk.ru
				</Typography>
			</div>
			<div className={ style.textBlock }>
				<Icon image={ MobileSVG } size='small' />
				<Typography type='body' className={ style.text }>
          +7 (926) 334 35 23
				</Typography>
			</div>
			<div className={ style.textBlock }>
				<Icon image={ ViberSVG } size='small' />
				<Link className={ style.text } href='viber://add?number=79263343523' type='outer' theme='default' size='medium'>
          Viber
				</Link>
			</div>
			<div className={ style.textBlock }>
				<Icon image={ TelegramSVG } size='small' />
				<Link className={ style.text } href='tg://resolve?domain=nedorogokupili.ru' type='outer' theme='default' size='medium'>
          Telegram
				</Link>
			</div>
			<div className={ style.textBlock }>
				<Icon image={ WhatsAppSVG } size='small' />
				<Link className={ style.text } href='https://api.whatsapp.com/send?phone=79263343523' type='outer' theme='default' size='medium'>
          WhatsApp
				</Link>
			</div>
		</>
	);
};
