import style from './style.module.scss';
import { Typography } from '../typography/typography';
import { ButtonLink } from '../buttonLink/buttonLink';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';

interface BannerProps {
  	bgColor?: string,
  	title: string,
  	subtitle: string,
  	description: string,
	buttonText?: string,
  	image: string,
  	href: string
}

export const Banner = ({ bgColor, description, image, subtitle, href, title, buttonText }: BannerProps): JSX.Element => {
	const bannerStyle = {
		backgroundColor: bgColor
	};

	return (
		<div role='banner' className={ style.banner } style={ bannerStyle }>
			<Typography type='h4' tag='span' className={ style.subtitle }>{ subtitle }</Typography>
			<Typography type='bgTitle' tag='span' className={ style.title }>{ title }</Typography>
			<Typography type='body' className={ style.description }>{ description }</Typography>
			<ToggleVisibility visible={ !!href }>
				<ButtonLink className={ style.button } type='inner' href={ href }>{ buttonText }</ButtonLink>
			</ToggleVisibility>
			<img className={ style.image } src={ image } alt='ЛЕТО 2021'/>
		</div>
	);
};

Banner.defaultProps = {
	bgColor: '#ffffff',
	buttonText: 'Коллекция',
	href: null
};
