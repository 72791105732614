import storage from '../storage';

export const setToken = async (token: string): Promise<void> => {
	return storage.set('JWT', token);
};

export const getToken = async (): Promise<string | null> => {
	return storage.get('JWT');
};

export const removeToken = async (): Promise<void> => {
	return storage.remove('JWT');
};