import cs from 'classnames';
import style from './style.module.scss';
import { Typography } from '../typography/typography';
import { isKeyboardPress } from '../../lib/utils/isKeyboardPress';

interface RateProps {
  maxRate: number,
  currentRate: number,
  numRate?: boolean,
  className?: string,
  interactive: boolean,
  size?: 'small' | 'large',
  onChange?: (rate: number) => void
}

export const Rate = ({ maxRate, currentRate, numRate, className, interactive, size, onChange }: RateProps): JSX.Element => {
	let rateID = 0;

	const rating = Array.from({ length: maxRate }, (_, index) => {
		const handleClick = () => onChange && onChange(index + 1);

		const handleKeyboard = (e: any) => (isKeyboardPress(e) && onChange) && onChange(index + 1);

		rateID++;

		const styles = {
		  cursor: interactive ? 'pointer' : 'initial'
		};

		return (
			<i className={ style.star } style={ styles } role='button' tabIndex={ interactive ? 0 : -1 } key={ rateID } onClick={ interactive ? handleClick : undefined } onKeyDown={ interactive ? handleKeyboard : undefined } data-size={ size }>
				<svg className={ style.icon } aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
					<path fill={ index + 1 <= currentRate ? '#FFDA55' : 'var(--dark-grey-color)' } d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" />
				</svg>
			</i>
		);
	});

	return (
		<div className={ cs(style.rate, className) }>
			{ numRate === true && <Typography className={ style.appraisal } type='body'>{ currentRate }</Typography> }
			{ rating }
		</div>
	);
};

Rate.defaultProps = {
	numRate: false,
	className: true,
	size: 'small',
	onChange: null
};
