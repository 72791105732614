import style from './style.module.scss';
import { Section } from '../../components/section/section';
import { Typography } from '../../components/typography/typography';
import { ReactComponent as GeoSVG } from '../../images/geo.svg';
import { ReactComponent as TimeSVG } from '../../images/alarm.svg';
import { ReactComponent as TrackSVG } from '../../images/track.svg';
import { ReactComponent as StorySVG } from '../../images/stories.svg';
import { Icon } from '../../components/icon/icon';
import { ChosenItem } from '../../components/chosenItem';
import { ChosenItems } from '../../components/chosenItems/chosenItems';
import { withRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PersonalData } from '../../lib/enums/responses';
import { useRecoilValue } from 'recoil';
import { sizeSelector } from '../../recoil/selectors';
import cs from 'classnames';
import { BasketItem } from '../../lib/storage/adapters/basket.adapter';
import { orderFetch } from '../../lib/api/rest/order/order';
import { useFetchStatus } from '../../lib/utils/hooks/useFetchStatus';
import { FetchStatus } from '../../lib/enums/fetchStatuses';
import { FetchResponse } from '../../components/fetchStatus/fetchStatus';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';

export const Order = withRouter(({ match }): JSX.Element => {
	const [personal, setPersonal] = useState<PersonalData>();
	const [items, setItems] = useState<BasketItem[]>();
	const [pickupDate, setPickUpDate] = useState<string>();
	const [deliveryTime, setDeliveryTime] = useState<string>();
	const [fetchStatus, setFetchStatus] = useFetchStatus();
	const { sizeByID } = useRecoilValue(sizeSelector);

	const orderID = match.params.orderID;

	useEffect(() => {
		orderFetch({
			id: orderID
		}).then((res) => {
		  	const { customer, items } = res.data || {};

			setFetchStatus(items ? FetchStatus.SUCCESS : FetchStatus.NOT_FOUND);

			setDeliveryTime(res.data?.delivery_time);
			setPickUpDate(res.data?.pickup_date);
			setItems(items);
		  	setPersonal(customer);
		}).catch(() => setFetchStatus(FetchStatus.NOT_FOUND));
	}, []);

	const address = `${personal?.city != null ? personal.city + ',' : ''} ${ personal?.street ?? ''} ${personal?.house != null ? personal.house + ',' : ''} ${personal?.flat ?? ''}`;

	return (
		<MainTemplate>
			<Section title='Заказ'>
				<Typography type='h3' tag='h1'>Информация о заказе</Typography>
				<div className={ style.container }>
					<FetchResponse status={ fetchStatus } error='Мы обыскали все, но так и не смогли найти ваш заказ'>
						<div className={ style.section }>
							<Typography type='h4' tag='h2' className={ style.sectionTitle }>Данные доставки</Typography>
							<ul className={ style.orderInfo }>
								{ address.trim().length > 0 && <li className={ style.orderInfoField }><Icon image={ GeoSVG } size='small' className={ style.icon }/>{ `Адрес: ${address}` }</li> }
								{ deliveryTime && <li className={ style.orderInfoField }><Icon image={ TimeSVG } size='small' className={ style.icon } />{ `Время доставки: ${deliveryTime}` }</li> }
								{ personal?.index && <li className={ style.orderInfoField }><Icon image={ TrackSVG } size='small' className={ style.icon } />{ `Почтовый индекс: ${personal?.index}` }</li> }
								{ pickupDate && <li className={ style.orderInfoField }><Icon image={ StorySVG } size='small' className={ style.icon } />{ `Дата самовывоза: ${pickupDate}` }</li> }
							</ul>
						</div>
						<div className={ cs(style.orderItemsContainer, style.section) }>
							<Typography type='h4' tag='h2' className={ style.sectionTitle }>Заказ</Typography>
							<ChosenItems>
								{ items && items.map((item) => {
									const { id, name, imageURL, size, count } = item;

									return (
										<ChosenItem id={ id } key={ id } className={ style.item } title={ name } subtitle={ `Артикул ${id}` } image={ imageURL } characteristics={
											[{
												option: 'Размер',
												value: sizeByID(size)?.text
											},
											{
												option: 'Количество',
												value: count
											}]
										}
										/>
									);
								}) }
							</ChosenItems>
						</div>
					</FetchResponse>
				</div>
			</Section>
		</MainTemplate>
	);
});
