export const sexStatus: Record<number, string> = {
	1: 'Мужской',
	2: 'Женский',
	3: 'Унисекс'
};

export const deliveryStatus: Record<number, string> = {
	1: 'Доставка',
	2: 'Самовывоз',
	3: 'Отправка'
};

export const paymentStatus: Record<number, string> = {
	1: 'Оплата заказа при получении (Москва и МО)',
	2: 'Онлайн оплата картой',
	3: 'Онлайн плата переводом',
	4: 'Наложенный платеж',
	5: 'Оплатить 50%',
	6: 'Безопасная сделка'
};