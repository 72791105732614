import { request } from '../../request';

interface UsersFetchParams {
  count: number,
  offset: number,
}

export interface IUserResponse {
  email: string,
  phone: string,
  id: string,
  orderAutocomplete: {
    flat: string,
    house: string,
    method: number,
    street: string
  }
}

export const usersFetch = ({ count, offset }: UsersFetchParams) => {
	return request({ url: '/admin/users', params: { count, offset }, headers: { authorization: true } });
};
