import { useRecoilState } from 'recoil';
import { isMobileAtom } from '../../recoil/atoms';
import { ChosenItemProps, DesktopChosenItem } from './desktopChosenItem/chosenItem';
import { MobileChosenItem } from './mobileChosenItem/mobileChoseItem';

export const ChosenItem = (props: ChosenItemProps): JSX.Element => {
	const [isMobile] = useRecoilState(isMobileAtom);

	if (isMobile) return <MobileChosenItem { ...props } />;

	return <DesktopChosenItem { ...props } />;
};
