import { Link as RouterLink } from 'react-router-dom';
import style from './style.module.scss';
import cs from 'classnames';

export interface GeneralLinkProps {
	type: 'inner' | 'outer',
	href: string,
	children?: JSX.Element | JSX.Element[] | string,
	className?: string,
	target?: string,
	download?: boolean,
}

interface LinkProps extends GeneralLinkProps {
	size?: 'small' | 'medium' | 'large',
	theme?: 'primary' | 'secondary' | 'default',
	onClick?: () => void
}

const themes = {
	primary: style.primaryLinkTheme,
	secondary: style.secondaryLinkTheme,
	default: style.defaultLinkTheme
};

export const Link = ({ type, href, children, theme, size, className, target, download, onClick }: LinkProps): JSX.Element => {
	if (type === 'inner') {
		return <RouterLink onClick={ onClick } className={ cs(style.link, themes[theme!], className) } download={ download } target={ target } to={ href } data-size={ size }>{ children }</RouterLink>;
	} else {
	  return <a onClick={ onClick } className={ cs(style.link, themes[theme!], className) } download={ download } target={ target } href={ href } data-size={ size }>{ children }</a>;
	}
};

Link.defaultProps = {
	theme: 'primary',
	className: null,
	size: undefined,
	target: null,
	download: null,
	onClick: undefined
};



