import style from './style.module.scss';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';

interface LoaderLineProps {
  visibility: boolean
}

export const LoaderLine = ({ visibility }: LoaderLineProps) => {
	return (
		<ToggleVisibility visible={ visibility }>
			<div className={ style.loadingLine } />
		</ToggleVisibility>
	);
};
