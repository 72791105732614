import { MutableRefObject, RefObject, useCallback, useEffect, useRef } from 'react';

interface UseOutsideOptions {
  	capturing?: boolean,
  	stopPropagation?: boolean
	exclude?: RefObject<any>[]
}

export const useOutside = <T extends HTMLElement>(handler: () => void, options?: UseOutsideOptions) => {
	const { capturing, stopPropagation, exclude } = options || {};

	const ref: MutableRefObject<T | undefined> = useRef();

	const isOutside = useCallback((e: any) => {
		stopPropagation && e.stopPropagation();

	  	if (ref && ref.current) {
			const foundElement = exclude?.find((elem) => elem.current.contains(e.target));

			if (!ref.current.contains(e.target) && !foundElement?.current) handler();
		}
	}, [ref, handler]);

	useEffect(() => {
		document.addEventListener('click', isOutside, { capture: capturing });

		return () => document.removeEventListener('click', isOutside, { capture: capturing });
	}, [isOutside]);

	return ref;
};
