import ReactDOM from 'react-dom';
import style from './style.module.scss';
import { useSpring, animated } from 'react-spring';

import { ReactComponent as closeSVG } from '../../images/close.svg';
import { IconButton } from '../iconButton/iconButton';

interface DrawerProps {
  visible: boolean,
  onClose: () => void,
  position: 'right' | 'left',
  children: JSX.Element | JSX.Element[] | undefined
}

const positionStyle = {
	left: {
		left: 0
	},
	right: {
		right: 0
	}
};

export const Drawer = ({ visible, onClose, position, children }: DrawerProps): JSX.Element => {
	const drawerAnimation = useSpring({
		opacity: visible ? 1 : 0,
		transform: visible ? 'translateX(0)' : position === 'left' ? 'translateX(-100%)' : 'translateX(100%)'
	});

	const overlayAnim = useSpring({
		background: visible ? 'rgba(00, 00, 00, 0.3)' : 'rgba(00, 00, 00, 0)',
		display: visible ? 'block' : 'none'
	});

	return ReactDOM.createPortal(
	  	<animated.div style={ overlayAnim } className={ style.overlay }>
			<animated.div className={ style.drawer } style={ { ...positionStyle[position], ...drawerAnimation } }>
				<IconButton className={ style.closeIcon } icon={ closeSVG } onClick={ onClose } />
				<div className={ style.content }>{ children }</div>
			</animated.div>
		</animated.div>
		, document.body);
};

Drawer.defaultProps = {
	onClickOutside: null
};
