import { Typography } from '../typography/typography';
import { useRecoilValue } from 'recoil';
import { categorySelector } from '../../recoil/selectors';
import style from './style.module.scss';
import memoize from 'lodash.memoize';
import cs from 'classnames';
import { useHistory } from 'react-router';

interface CategoriesGroupProps {
  search: string,
  onMouseOver?: (value: number) => void,
  onClick?: (value: number) => void,
  chosenID: number | undefined
}

export const CategoriesGroup = ({ search, onMouseOver, chosenID, onClick }: CategoriesGroupProps) => {
	const { searchBySubcategory } = useRecoilValue(categorySelector);
	const history = useHistory();
	const foundCategories = memoize(() => searchBySubcategory(search));

	return (
		<>
			{ foundCategories()?.map((category) => {
				const { categoryLabel, id } = category;

				const handleMouse = () => onMouseOver && onMouseOver(id);

				const handleClick = () => {
					onClick && onClick(id);
					history.push(`/items/${id}`);
				};

				return <Typography onMouseOver={ handleMouse } onClick={ handleClick } className={ cs(style.categoryGroup, { [style.active]: id === chosenID }) } key={ id } type='h5' tag='h3'>{ categoryLabel }</Typography>;
			})
			}
		</>
	);
};

CategoriesGroup.defaultProps = {
	onMouseOver: undefined,
	onClick: undefined
};
