import { IconButton } from '../../iconButton/iconButton';
import { ReactComponent as FBSVG } from '../../../images/social/fb.svg';
import { ReactComponent as VKSVG } from '../../../images/social/vk.svg';
import { ReactComponent as InstSVG } from '../../../images/social/inst.svg';
import { ReactComponent as AvitoSVG } from '../../../images/social/avito.svg';
import { ReactComponent as YoulaSVG } from '../../../images/social/youla.svg';
import { Link } from '../../link/link';

interface SocialLinksProps {
  className?: string
}

export const SocialLinks = ({ className }: SocialLinksProps): JSX.Element => {
	return (
		<>
			<Link type='outer' className={ className } theme='default' href='https://www.instagram.com/catcat.store/'>
				<IconButton icon={ FBSVG } size='medium' />
			</Link>
			<Link type='outer' className={ className } theme='default' href='https://vk.com/nedorogorupili'>
				<IconButton icon={ VKSVG } size='medium' />
			</Link>
			<Link type='outer' className={ className } theme='default' href='https://www.avito.ru/user/6b50d4fe64be7bff4f6a37867f50923f/profile?id=2119434818&src=item&page_from=from_item_card&iid=2119434818'>
				<IconButton icon={ AvitoSVG } size='medium' />
			</Link>
			<Link type='outer' className={ className } theme='default' href='https://youla.ru/user/5a0eb9af65bcf16c16608b92'>
				<IconButton icon={ YoulaSVG } size='medium' />
			</Link>
		</>
	);
};

SocialLinks.defaultProps = {
	className: null
};
