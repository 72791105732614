import { useRecoilValue } from 'recoil';
import { categorySelector } from '../../../recoil/selectors';
import { useInput } from 'react-awesome-form';
import memoize from 'lodash.memoize';
import { useState } from 'react';

export const useMenuNav = () => {
	const { searchBySubcategory } = useRecoilValue(categorySelector);
	const search = useInput(true,'');
	const foundCategories = memoize(() => searchBySubcategory(search.value()));
	const [chosenCategoryID, setCategoryID] = useState<number | undefined>();

	return { chosenCategoryID, setCategoryID, categories: foundCategories, search };
};
