import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

export const axiosServer = axios.create();

axiosServer.interceptors.response.use((response) => {
	return response;
}, async (error) => {
	const origin = error.config;

	const status = error?.response?.status;

	if (status && (status === 403 || status === 401) && !origin._retry) {
		origin._retry = true;

		const { data: { JWT: newJWT } } = await axios.post(`${process.env.REACT_APP_API_URL}/user/refresh`, {
			fingerprint: window.navigator.userAgent
		});

		localStorage.setItem('JWT', newJWT);
		axiosServer.defaults.headers['Authorization'] = `Token ${newJWT}`;
		origin.headers['Authorization'] = `Token ${newJWT}`;

		return axiosServer(origin);
	}

	return Promise.reject(error);
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
