import { StyleHTMLAttributes } from 'react';
import { Typography } from '../typography/typography';
import cs from 'classnames';
import style from './style.module.scss';
import { Button } from '../button/button';
import { useRecoilState } from 'recoil';
import { purchaseModalAtom } from '../../recoil/atoms/purchaseModal';
import { Link } from 'react-router-dom';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';
import { limitText } from '../../lib/utils/limitText';

interface Badge {
  color: string,
  label: string
}

interface ItemCardProps {
  image: string,
  title: string,
  price: string,
  className?: string,
  badge?: Badge,
  style?: StyleHTMLAttributes<any>,
  itemID: number
}

export const ItemCard = ({ image, title, price, className, badge, itemID }: ItemCardProps): JSX.Element => {
	const [, setPurchaseModal] = useRecoilState(purchaseModalAtom);

	const badgeStyle = { backgroundColor: badge?.color };

	const handlePurchaseButtonClick = (e: any) => {
		e.preventDefault();

	  	setPurchaseModal({
			visible: true,
			id: itemID
		});
	};

	return (
		<Link to={ `/item/${itemID}` } className={ cs(style.itemCard, className) }>
			<img className={ style.image } src={ image } alt={ title } />
			<Typography type='body' tag='h4' className={ style.title }>{ limitText(title, 40) }</Typography>
			<Typography type='h5' tag='span'>{ price }</Typography>
			<ToggleVisibility visible={ !!badge }>
				<div className={ style.badge } style={ badgeStyle }>
					{ badge?.label }
				</div>
			</ToggleVisibility>
			<Button theme='third' onClickCapture={ handlePurchaseButtonClick } className={ style.buttonFastPurchase }>Купить</Button>
		</Link>
	);
};

ItemCard.defaultProps = {
	className: null,
	badge: null,
	style: null
};
