import { request } from '../../request';

interface IMeUpdate {
	email: string,
	phone: string,
	orderAutocomplete: {
		flat: string,
		street: string,
		house: string
	}
}

export const meFetch = () => {
	return request({ url: '/profile/me', headers: { authorization: true } });
};

export const meUpdate = (user: IMeUpdate) => {
	return request({ url: '/profile/me', method: 'PUT', data: { user }, headers: { authorization: true } });
};
