import style from './style.module.scss';
import { LinkBlock } from '../../components/linkBlock/linkBlock';
import { Section } from '../../components/section/section';

import sale from '../../images/customers/img.png';
import purchase from '../../images/customers/img_1.png';
import returnImg from '../../images/customers/img_2.png';
import delivery from '../../images/customers/img_3.png';
import about from '../../images/customers/img_4.png';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';

export const Customers = (): JSX.Element => {
	return (
		<MainTemplate>
			<Section className={ style.qualitySection } title='Для покупателя'>
				<div className={ style.linkGroup }>
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/customers/1' bgImage={ sale } title='Скидки' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/customers/2' bgImage={ purchase } title='Правила покупки' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/customers/3' bgImage={ returnImg } title='Правила возврата и обмена' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/customers/4' bgImage={ delivery } title='Правила доставки' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/customers/5' bgImage={ about } title='Об интернет-платформе' />
				</div>
			</Section>
		</MainTemplate>
	);
};
