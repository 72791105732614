import style from './style.module.scss';
import { Icon } from '../../icon/icon';
import { ReactComponent as VisaSVG } from '../../../images/visa.svg';
import { ReactComponent as MastercardSVG } from '../../../images/mastercard.svg';
import { ReactComponent as MirSVG } from '../../../images/mir.svg';
import { ReactComponent as PaypalSVG } from '../../../images/paypal.svg';

export const FooterPayment = (): JSX.Element => {
	return (
		<div className={ style.paymentIcons }>
			<Icon className={ style.paymentIcon } image={ VisaSVG } size='slt' />
			<Icon className={ style.paymentIcon } image={ MastercardSVG } size='slt' />
			<Icon className={ style.paymentIcon } image={ MirSVG } size='slt' />
			<Icon className={ style.paymentIcon } image={ PaypalSVG } size='slt' />
		</div>
	);
};
