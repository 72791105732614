import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { usePagination } from '../../../lib/utils/hooks/usePagination';
import {
	reviewAdminApprove,
	reviewAdminDelete,
	reviewsAdminFetch,
	ReviewsAdminFetchResponse
} from '../../../lib/api/rest/admin/reviews';
import { AdminTable } from '../adminTable/adminTable';
import { ModalReview } from '../modalReview/modalReview';
import { isLoggedAtom } from '../../../recoil/atoms';
import { useURL } from '../../../lib/utils/hooks/useURL';
import { useHistory, useLocation } from 'react-router';


export const ReviewsAdminTable = () => {
	const { search } = useLocation();
	const history = useHistory();

	const [isLogged] = useRecoilState(isLoggedAtom);
	const [reviewsCount, setReviewsCount] = useState(0);
	const [reviewModal, setReviewModal] = useState<{ visible: boolean, text: string, id: string | undefined}>({
		visible: false,
		text: '',
		id: undefined
	});
	const [reviews, setReviews] = useState([]);
	const { pageCount, filter } = usePagination(10, reviewsCount, 1);

	const { getParams, addParam } = useURL(search, history);

	useEffect(() => {
		addParam('page')(1);
	}, []);

	const { page } = getParams() || {};
	const handleChangePage = (page: number) => addParam('page')(page);

	useEffect(() => {
		isLogged && reviewsAdminFetch({ count: filter, offset: (page as number - 1) * filter }).then((res) => {
			const { reviews, count } = res?.data || {};

			const reviewsArray = reviews?.map((review: ReviewsAdminFetchResponse) => {
				const { email, firstname, lastname, rating, phone, text, id, isgood } = review;

				return {
				  id,
					data: [
						{
							title: 'Email',
							label: email
						},
						{
							title: 'Телефон',
							label: phone
						},
						{
							title: 'Имя',
							label: firstname
						},
						{
							title: 'Фамилия',
							label: lastname
						},
						{
							title: 'Рейтинг',
							label: rating
						},
						{
							title: 'Статус',
							label: isgood ? 'Одобрено' : 'На модерации'
						},
						{
							label: 'Подробнее',
							action: () => setReviewModal({
								visible: true,
								text,
								id
							})
						}
					]
				};
			});

			setReviewsCount(count);
			setReviews(reviewsArray);
		});
	}, [isLogged]);

	const columns = ['Email', 'Телефон', 'Имя', 'Фамилия', 'Рейтинг', 'Статус'];

	const closeModalReview = () => setReviewModal(prev => ({ ...prev, visible: false }));

	const deleteReview = (id: string | undefined) => {
	  reviewAdminDelete(id).then(() => {
			const filteredItems = reviews.filter((review: any) => review?.id !== id);
			setReviews(filteredItems);
			closeModalReview();
		});
	};

	const approveReview = (id: string | undefined) => reviewAdminApprove(id);

	const { id, visible, text }= reviewModal || {};

	return (
	  <>
			<ModalReview id={ id } onClose={ closeModalReview } visible={ visible } text={ text } onDelete={ deleteReview } onApprove={ approveReview } />
			<AdminTable data={ reviews } columns={ columns } handleChangePage={ handleChangePage } page={ +page } pageCount={ pageCount } />
		</>
	);
};

ReviewsAdminTable.defaultProps = {
	page: 1,
	onChoosePage: undefined,
	pageCount: undefined
};
