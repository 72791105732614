export const columnArray = (array: string[] | number[] | Record<any, any>, col: number) => {
	const m = Math.ceil(array?.length / col);

	return [...Array(col)].map((elem: any, index: number) => {

		/*
			If all the elements of the array can fit in columns
			(for example, 9 elements | 4 columns | 9 / 4 ~ 3 (Rounded up))
			-> the last cells need to be distributed evenly among themselves
			(for this, when iterating from the number of all elements, the
			number of remaining elements is subtracted from the penultimate
			element (from which you need a uniform distribution), then divided
			by the index of the column and rounded up (For example, there are 3
			elements left on the 2nd column -> total there are 2 more columns left
			to fill -> 3 / 2 ~ 2 (2 elements on the penultimate and last column).
		 */

		const sep = (array?.length !== m && array?.length % m === 0 && index >= col - 2 && col > 3) ? Math.ceil((array?.length - ((col - 2) * m)) / index) : m;

		const start = index * sep;

		return array?.slice(start, start + sep);
	});
};
