import style from './style.module.scss';
import cs from 'classnames';
import { isKeyboardPress } from '../../../lib/utils/isKeyboardPress';

interface SelectOptionProps {
  className?: string,
  value: string | number,
  onClick?: (value: string | number) => void,
  onKeyDown?: (value: string | number) => void,
  children?: string | number
}

export const SelectOption = ({ onClick, onKeyDown, children, className, value }: SelectOptionProps): JSX.Element => {
	const handleClick = () => onClick && onClick(value);
	const handleKeyDown = (e: any) => (onKeyDown && isKeyboardPress(e)) && onKeyDown(value);

	return (
		<li className={ cs(style.option, className) } onClick={ handleClick } onKeyDown={ handleKeyDown } role='option' aria-selected='true' tabIndex={ 0 }>{ children }</li>
	);
};

SelectOption.defaultProps = {
	className: null,
	onKeyDown: null,
	onClick: null,
	children: null
};
