import style from './style.module.scss';
import { Section } from '../../components/section/section';
import { Typography } from '../../components/typography/typography';
import { Input } from '../../components/input/input';
import { Button } from '../../components/button/button';
import { MutableRefObject, useEffect, useRef } from 'react';
import { RegExp } from '../../lib/enums/regExp';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../recoil/atoms';
import { useInput } from 'react-awesome-form';
import { meUpdate } from '../../lib/api/rest/profile/me';
import { OrdersContainer } from '../../containers/ordersContainer/ordersContainer';
import { Pagination } from '../../components/pagination/pagination';
import { usePagination } from '../../lib/utils/hooks/usePagination';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';
import { orderCountAtom } from '../../recoil/atoms/ordersCounts';
import { ToggleVisibility } from '../../components/toggleVisibility/toggleVisibility';
import { useHistory, useLocation } from 'react-router';
import { useURL } from '../../lib/utils/hooks/useURL';

export const Profile = (): JSX.Element => {
	const history = useHistory();
	const location = useLocation();

	const { getParams, addParam } = useURL(location.search, history);
	const { page } = getParams() || {};

	const [userData, setUserData] = useRecoilState(userAtom);
	const deliveryData = useRef() as MutableRefObject<any>;
	const personalData = useRef() as MutableRefObject<any>;
	const [ordersCount] = useRecoilState(orderCountAtom);
	const { pageCount, filter } = usePagination(5, ordersCount, 1);

	const email = useInput<string>(true, '', { regExp: RegExp.EMAIL });
	const mobile = useInput<string>(true, '', { regExp: RegExp.MOBILE });
	const flat = useInput<string>(true, '');
	const street = useInput<string>(true, '');
	const house = useInput<string>(true, '');

	useEffect(() => {
		if (userData.email) email.setValue(userData?.email ?? '');
		if (userData.mobile) mobile.setValue(userData.mobile ?? '');
		if (userData.flat) flat.setValue(userData.flat ?? '');
		if (userData.street) street.setValue(userData.street ?? '');
		if (userData.house) house.setValue(userData.house ?? '');
	}, [userData]);

	const updateUser = () => {
	  const data = {
			email: email.value(),
			phone: `+${mobile.value()}`,
			orderAutocomplete: {
				flat: flat.value(),
				street: street.value(),
				house: house.value()
			}
		};

	  	meUpdate(data).then(() => {
			// @ts-ignore
			setUserData(currVal => ({ ...currVal, ...data, mobile: mobile.value }));
		});
	};

	return (
		<MainTemplate>
			<Section title='Профиль'>
				<div className={ style.userData }>
					<form className={ style.fieldGroup } ref={ deliveryData }>
						<Typography type='h4' tag='h3' className={ style.sectionTitle }>Адрес доставки</Typography>
						<Input value={ street.value() } { ...street.handlers } className={ style.field } type='text' placeholder='Улица' />
						<Input value={ house.value() } { ...house.handlers } className={ style.field } type='text' placeholder='Дом/корпус' />
						<Input value={ flat.value() } { ...flat.handlers } className={ style.field } type='text' placeholder='Квартира' />
					</form>
					<form className={ style.fieldGroup } ref={ personalData }>
						<Typography type='h4' tag='h3' className={ style.sectionTitle }>Личные данные</Typography>
						<Input value={ mobile.value() } { ...mobile.handlers } error={ mobile.error } errorMsg='Пожалуйста, укажите правильный номер телефона' className={ style.field } type='text' placeholder='Номер телефона'/>
						<Input value={ email.value() } { ...email.handlers } error={ email.error } errorMsg='Пожалуйста, укажите почту' className={ style.field } type='text' placeholder='Email'/>
					</form>
				</div>
				<Button onClick={ updateUser } className={ style.button } theme='primary'>Сохранить</Button>
				<div className={ style.ordersBlock }>
					<Typography type='h4' tag='h3' className={ style.sectionTitle }>Заказы</Typography>
					<OrdersContainer className={ style.orders } page={ +page } count={ filter } />
					<ToggleVisibility visible={ pageCount > 0 }>
						<Pagination currentPage={ +page } defaultPage={ 1 } pageCount={ pageCount } onChange={ addParam('page') } />
					</ToggleVisibility>
				</div>
			</Section>
		</MainTemplate>
	);
};
