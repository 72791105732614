interface ToggleVisibilityProps {
  visible: boolean,
  children: JSX.Element | JSX.Element[],
  display?: boolean
}

export const ToggleVisibility = ({ visible, children }: ToggleVisibilityProps): JSX.Element => {
	return (
		<>
			{ visible && children }
		</>
	);
};

ToggleVisibility.defaultProps = {
	display: false
};
