import { atom } from 'recoil';
import { Category } from '../lib/enums/responses';
import { BasketItem } from '../lib/storage/adapters/basket.adapter';

export const userAtom = atom({
	key: 'user',
	default: {
	  mobile: '',
		email: '',
		house: '',
		flat: '',
		street: ''
	}
});

export const isMobileAtom = atom({
	key: 'windowSize',
	default: false
});

export const itemSizesAtom = atom({
	key: 'itemSizes',
	default: []
});

export const itemCategoriesAtom = atom<Category[]>({
	key: 'itemCategories',
	default: []
});

export const itemBrandsAtom = atom({
	key: 'itemBrands',
	default: []
});

export const isLoggedAtom = atom({
	key: 'isLogged',
	default: false
});

export const itemsCount = atom({
	key: 'itemsCount',
	default: 0
});

export const reviewsCount = atom({
	key: 'reviewsCount',
	default: 0
});

export const userBasketAtom = atom<BasketItem[] | undefined>({
	key: 'userBasket',
	default: undefined
});
