import { request } from '../request';
import { BasketItem } from '../../storage/adapters/basket.adapter';

interface ICartRequest {
  	cart: BasketItem[] | undefined,
  	modified_date: Date;
}

export const cartPut = (data: ICartRequest) => {
	return request({ url: '/cart', method: 'PUT', data, headers: { authorization: true } });
};

export const cartFetch = () => {
	return request({ url: '/cart', method: 'GET', headers: { authorization: true } });
};
