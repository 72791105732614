import style from './style.module.scss';
import { Input } from '../input/input';
import { Link } from '../link/link';
import { Menu } from '../menu/menu';
import { MutableRefObject, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useInput } from 'react-awesome-form';
import debounce from 'lodash.debounce';
import { reverseImageSearch, searchFetch } from '../../lib/api/rest/search';
import cs from 'classnames';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';
import { Jimp as JimpType, JimpConstructors } from '@jimp/core';
import 'jimp';

declare const Jimp: JimpType & JimpConstructors;

interface SearchMenuProps {
	onClose: () => void,
	refEl: MutableRefObject<any>,
	controllerEl: MutableRefObject<any>,
	visible: boolean
}

export const SearchMenu = ({ onClose, refEl, visible, controllerEl }: SearchMenuProps) => {
	const searchInput = useInput(true, '');
	const searchOnInput = debounce((e: any) => searchInput.handlers.onInput(e), 500);
	const history = useHistory();
	const [result, setResult] = useState([]);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [isDragging, setIsDragging] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	

	useEffect(() => {
		let isMounted = true;

		console.log(selectedFile);

		if (searchInput.value()) {
			searchFetch({ query: searchInput.value() }).then((res) => {
				isMounted && setResult(res?.data?.items ?? []);
			});
		}

		return () => {
			isMounted = false;
		};
	}, [searchInput.value()]);

	const handleFileChange = async (file: File | undefined) => {
		if (file) {
			setSelectedFile(file);
			const reader = new FileReader();
			reader.onload = async (e) => {
				const buffer = e.target?.result as ArrayBuffer;
				if (buffer) {
					const imageBlob = new Blob([file], {type: file.type});
					const image = await Jimp.read(Buffer.from(buffer));
					const hash = image.hash();
					// let imageByHash = JSON.parse(localStorage.getItem('imageByHash') || '{}');
					// imageByHash[hash] = URL.createObjectURL(file);
					// localStorage.setItem('imageByHash',JSON.stringify(imageByHash))
					setIsSearching(true);
					await reverseImageSearch({ query: `hash=${hash}`, hash:hash, emptyAnswer:true}, file)
					setIsSearching(false);
					history.push(`/search/hash=${hash}`);
				}
			};
			reader.readAsArrayBuffer(file);
		}
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(true);
	};

	const handleDragLeave = () => {
		setIsDragging(false);
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(false);
		const file = e.dataTransfer.files?.[0];
		if (file) {
			handleFileChange(file);
		}
	};

	const handleFileIconClick = () => {
		const fileInput = document.getElementById('fileInput');
		if (fileInput) {
			fileInput.click();
		}
	};

	return (
		<Menu controllerRef={controllerEl} className={cs(style.searchMenu, { [style.dragging]: isDragging })} onClose={onClose} refEl={refEl} visible={visible}>
			<div onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
				<div className={style.searchFieldContainer}>
					<Input className={style.searchInput} type='text' onInput={searchOnInput} placeholder='Поиск' />
					<img
						src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAmklEQVR4nGNgGAWDCXgwMDA8ZmBg+E8hfgQ1CwM8ooLh/5EswQAwSUrBf1zmjFowtIJImIGBYR0DAwM3NSywQONzMjAwHIOqnU+pBa4MDAx/GBgYOqB8JgYGhjVoaT6BXAsUGBgY3iKJNzAwMPRjyVRfGBgYtEm1gIOBgeEMCTn3KlJ8DI1UhA/gNIfmhZ0HlSx5hKu4HgUDAwDsxJnie8bBxgAAAABJRU5ErkJggg=="
						alt=""
						className={style.searchIcon}
						onClick={handleFileIconClick}
						title='Искать по картинке'
					/>
				</div>
				<div className={style.results}>
					<ToggleVisibility visible={result.length > 0}>
						<Link className={cs(style.resultLink, style.showMore)} href={`/search/${searchInput.value()}`} type='inner' size='medium' theme='default'>Показать похожие</Link>
					</ToggleVisibility>
					{result?.map?.((result, index) => {
						const { id, name } = result || {};
						return <Link key={index} className={style.resultLink} href={`/item/${id}`} type='inner' size='medium' theme='default'>{`${name} | ID${id}`}</Link>;
					})}
				</div>
				<input type='file' id='fileInput' style={{ display: 'none' }} onChange={(e) => handleFileChange(e.target.files?.[0])}></input>
			</div>
			<div className={cs(style.loaderBlock, { [style.isSearching]: isSearching })}><span className={style.loaderSpiner}></span></div>
		</Menu>
	);
};
