import style from './style.module.scss';
import { Typography } from '../typography/typography';
import { Button } from '../button/button';
import { useState } from 'react';
import cs from 'classnames';
import { Rate } from '../rate/rate';

interface ReviewProps {
  author: string,
  review: string,
  textLimit: number,
  className?: string,
  rating?: number
}

export const Review = ({ author, review, textLimit, className, rating }: ReviewProps): JSX.Element => {
	const [isVisibleReview, setVisibleReview] = useState<boolean>(review.length < textLimit);
	const isHidden = isVisibleReview || review.length < textLimit;

	const cropText = (text: string, length: number) => text.substr(0, length);
	const showReview = () => setVisibleReview(prevState => !prevState);

	return (
	  <div className={ cs(style.review, className) }>
			<div className={ style.head }>
				<Typography type='h5' tag='span' className={ style.author }>{ author }</Typography>
				<svg className={ style.reviewIcon } viewBox="0 0 45 25" fill="#00C9FF" xmlns="http://www.w3.org/2000/svg">
					<path d="M9.47797 0H22.7987L13.3208 25H0L9.47797 0Z"/>
					<path d="M31.6792 0H45L35.522 25H22.2013L31.6792 0Z"/>
				</svg>
			</div>
			{ rating && <Rate interactive={ false } className={ style.rating } currentRate={ rating } maxRate={ 5 } /> }
			<Typography type='body' className={ style.reviewText }>{ isHidden ? review : cropText(review, textLimit) + '...' }</Typography>
			{ review.length > textLimit && <Button theme='text' onClick={ showReview } className={ style.button }>{ review.length > textLimit && isVisibleReview ? 'Скрыть' : 'Показать' }</Button> }
		</div>
	);
};

Review.defaultProps = {
	className: null,
	rating: null
};
