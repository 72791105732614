import style from './style.module.scss';
import { Typography } from '../typography/typography';
import cs from 'classnames';

interface LabelProps {
    label: string | number,
    className?: string
}

export const Label = ({ label, className }: LabelProps) => {
	return (
		<div className={ cs(style.label, className) }><Typography type='subtitle'>{ label }</Typography></div>
	);
};

Label.defaultProps = {
	className: undefined
};