import style from './style.module.scss';
import cs from 'classnames';
import { Children, cloneElement } from 'react';
import { RadioButton } from '../../RadioButton/radioButton';
import { IValue } from '../select';

interface SelectListProps {
  onChange: (value: string| number) => void,
  onChangeKeyBoard: (value: string| number, e: any) => void,
  value: IValue | undefined,
  children: JSX.Element | JSX.Element[] | undefined,
  multipleButton?: boolean
}

export const SelectList = ({ onChange, onChangeKeyBoard, children, value, multipleButton }: SelectListProps): JSX.Element => {

	const options = Children.map(children, (elem) => {
		let childrenProps = elem && elem.props.children;

		const createOption = (elem: any) => {
			const isActive = Array.isArray(value) ? value.includes(elem.props.value as never) : value === elem.props.value;
			return cloneElement(elem, {
				className: cs(style.option, { [style.active]: isActive }),
				onClick: onChange,
				onKeyDown: onChangeKeyBoard
			},
			<>
				{ elem.props.children }
				{ multipleButton && <RadioButton checked={ isActive }/> }
			</>
			);
		};

		if (typeof elem?.props.children === 'object') {
		  const children = elem && elem.props.children;

			childrenProps = Children.map(children, (elem) => {
				return createOption(elem);
			});

			return elem && cloneElement(elem, { children: childrenProps });
		}

		return createOption(elem);
	});

	return (
		<ul className={ style.selectList }>
			{ options }
		</ul>
	);
};

SelectList.defaultProps = {
	multipleButton: false
};
