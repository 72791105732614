import { request } from '../../request';

interface ReviewsFetchParams {
  count: number,
  offset: number,
}

export interface ReviewsAdminFetchResponse {
  created_at: string,
  email: string,
  firstname: string,
  id: string,
  isgood: boolean,
  lastname: string,
  phone: string,
  rating: number,
  text: string
}

export const reviewsAdminFetch = (query: ReviewsFetchParams) => {
	return request({ url: '/admin/reviews', headers: { authorization: true }, params: query });
};

export const reviewAdminDelete = (id: string | undefined) => {
	return request({ url: '/admin/reviews', method: 'DELETE', headers: { authorization: true }, params: { id } });
};

export const reviewAdminApprove = (id: string | undefined) => {
	return request({ url: '/admin/reviews', method: 'PUT', headers: { authorization: true }, params: { id } });
};
