import { Typography } from '../../typography/typography';
import { Nav } from '../../navigation/nav';
import { NavLink } from '../../navLink/navLink';

interface FooterLinksProps {
  sectionClassName?: string,
  titleClassName?: string
}

export const FooterLinks = ({ sectionClassName, titleClassName }: FooterLinksProps) => {
	return (
		<>
			<div className={ sectionClassName }>
				<Typography type='h3' className={ titleClassName }>Магазин</Typography>
				<Nav orientation='vertical'>
					<NavLink to='/delivery' label='Доставка и оплата' />
					<NavLink to='/customers' label='Для покупателей' />
					<NavLink to='/reviews' label='Отзывы' />
				</Nav>
			</div>
			<div className={ sectionClassName }>
				<Typography type='h3' className={ titleClassName }>Юридическая информация</Typography>
				<Nav orientation='vertical'>
					<NavLink to='/policy' label='Политика конфиденциальности' />
					<NavLink to='/policy' label='Пользовательское соглашение' />
					<NavLink to='/policy' label='Политика обработки персональных данных' />
				</Nav>
			</div>
		</>
	);
};

FooterLinks.defaultProps = {
	titleClassName: null,
	sectionClassName: null
};
