//TODO: Переделать в CRM (Сделать на разные вариации различные артикулы)

interface ICreateItemID {
    id: number | undefined,
    size: number | null | undefined,
    image: number | null | undefined
}

export const createItemID = ({ id, image, size }: ICreateItemID) => {
	return `${id ?? ''}${size ?? 0}${image ?? ''}`.trim();
};