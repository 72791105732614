import style from './style.module.scss';
import { Typography } from '../../components/typography/typography';
import { Link } from '../../components/link/link';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Point } from '../../components/point/point';
import staticData from '../../static/customers.json';
import { withRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';

interface Point {
  title: string,
  text: string,
  id: number,
}

interface Quality {
  title: string,
  description: string,
  id: number,
  points?: Point[],
  image?: string
}

export const CustomersItem = withRouter(({ match }: any): JSX.Element => {
	const qualityID = +match.params.qualityID;

	const [title, setTitle] = useState<string | undefined>('');
	const [description, setDescription] = useState<string | undefined>('');
	const [points, setPoints] = useState<Point[] | undefined>([]);
	const [image, setImage] = useState<string | undefined>('');

	useEffect(() => {
		const foundQuality: Quality | undefined = staticData.find((quality) => quality.id === qualityID);

		setDescription(foundQuality?.description);
		setTitle(foundQuality?.title);
		setPoints(foundQuality?.points);
		setImage(foundQuality?.image);
	}, []);

	return (
		<MainTemplate>
			<Breadcrumbs>
				<Link href='/customers' type='inner' theme='default' size='medium' className={ style.mainBreadcrumbText }>Для покупателей</Link>
				<Typography type='body'>{ title }</Typography>
			</Breadcrumbs>
			<Typography type='body' className={ style.qualityText }>
				{ description }
			</Typography>
			<>
				{ /* eslint-disable-next-line @typescript-eslint/no-var-requires */ }
				{ image && <img className={ style.image } src={ require(`../../images/${image}`).default } alt={ '1' }/> }
				{ points?.map((point) => {
					const { text, title, id } = point || {};

					return (
						<Point key={ id } className={ style.point } title={ title } description={ text } />
					);
				}) }
			</>
		</MainTemplate>
	);
});
