import { refreshToken } from './rest/user/refresh';
import { setAuthToken } from './config';
import { request } from './request';
import { setToken } from '../storage';
import { removeToken } from '../storage/adapters/token.adapter';

export const errorHandler = (error: any, requestData: any) => {
	if ([403].includes(error?.response?.status) && requestData?.retryOnError) {
	  refreshToken({ fingerprint: window.navigator.userAgent }).then((res) => {
	  	if (res?.data?.JWT) {
				const { JWT } = res?.data;

				return setToken(JWT).then(() => {
					setAuthToken(JWT);

					return request({ ...requestData, retryOnError: false });
				});
			} else if (res.response?.status === 500) return removeToken();
	  });
	}
};
