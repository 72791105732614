import style from './style.module.scss';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Typography } from '../../components/typography/typography';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { ItemsContainer } from '../../containers/itemsContainer/itemsContainer';
import { useRecoilState, useRecoilValue } from 'recoil';
import { itemsCount, itemSizesAtom } from '../../recoil/atoms';
import { usePagination } from '../../lib/utils/hooks/usePagination';
import { withRouter } from 'react-router-dom';
import { categorySelector } from '../../recoil/selectors';
import { brandsForItemsFetch } from '../../lib/api/rest/shop/brands';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';
import { ItemsFilter } from '../../components/itemsFilter/itemsFilter';
import { Catalog } from '../../components/catalog/catalog';
import { useHistory } from 'react-router';
import { useInput } from 'react-awesome-form';
import { useURL } from '../../lib/utils/hooks/useURL';
import debounce from 'lodash.debounce';
import { IValue } from '../../components/select/select';

export const Items = withRouter(({ match: { params }, location: { search } }): JSX.Element => {
	const history = useHistory();

	const { getParams, addParam, resetParams } = useURL(search, history);
	const { page, brand, size, sex, mode, date, priceFrom, priceTo } = getParams() || {};

	const [priceFromValue, setPriceFrom] = useState(priceFrom);
	const [priceToValue, setPriceTo] = useState(priceTo);

	const debouncePriceFrom = useCallback(debounce((value: number | undefined) => value != null && setPriceFrom(+value as SetStateAction<any>), 500), []);
	const debouncePriceTo = useCallback(debounce((value: number | undefined) => value != null && setPriceTo(+value as SetStateAction<any>), 500), []);

	const priceFromInput = useInput<number | string>(false, isNaN(+priceFrom) ? '' : +priceFrom);
	const priceToInput = useInput<number | string>(false, isNaN(+priceTo) ? '' : +priceTo);

	const priceFromIn = { ...priceFromInput, handlers: {
		...priceFromInput.handlers,
		onInput: (e: any) => {
			const value = e.target.value;
			addParam('priceFrom')(value);
			priceFromInput.handlers.onInput(e);
			debouncePriceFrom(value);
		}
	} };

	const priceToIn = { ...priceToInput, handlers: {
		...priceToInput.handlers,
		onInput: (e: any) => {
			const value = e.target.value;
			addParam('priceTo')(value);
			priceToInput.handlers.onInput(e);
			debouncePriceTo(value);
		}
	} };

	const resetFilters = () => {
		resetParams(['page']);
		priceToIn.resetInput();
		priceFromIn.resetInput();
	};

	const categoryID = !isNaN(+params.categoryID) ? +params.categoryID : undefined;

	const [getItemsCount] = useRecoilState(itemsCount);
	const [getSizes] = useRecoilState(itemSizesAtom);
	const [brandsIDs, setBrandsIDs] = useState([]);

	const { findSubcategoryByID, searchCategory } = useRecoilValue(categorySelector);
	const { pageCount, filter } = usePagination(40, getItemsCount);

	const handleChangePage = (page: number) => {
		window.scrollTo(0, 0);

		addParam('page')(page);
	};

	const handleAddingParam = (key: string) => (value: IValue | undefined) => {
		if (value != null) {
			key !== 'page' && addParam('page')(1);
			addParam(key)(value);
		}
	};

	useEffect(() => {
		if (categoryID) {
			brandsForItemsFetch(categoryID).then((res) => {
				const ids = res.data?.map((brand: any) => brand._id);
				setBrandsIDs(ids);
			});
		}
	}, [categoryID]);

	const subcategoryObject = findSubcategoryByID(categoryID);
	const categoryObject = subcategoryObject && searchCategory(subcategoryObject?.text)[0];

	const sizes = getSizes?.filter((size: any) => categoryObject?.sizeID.includes(size?.sizeID));

	return (
		<MainTemplate>
			<Breadcrumbs>
				<Typography type='h4' tag='h1' className={ style.mainBreadcrumbText }>Каталог</Typography>
				<Typography type='body'>{ categoryObject?.categoryLabel }</Typography>
				<Typography type='body'>{ subcategoryObject?.text }</Typography>
			</Breadcrumbs>
			<Typography type='h3' className={ style.filterTitle }>Фильтры</Typography>
			<Catalog
				filter={
					<ItemsFilter
						onChangeSex={ handleAddingParam('sex') }
						onChangeMode={ handleAddingParam('mode') }
						onChangeBrand={ handleAddingParam('brand') }
						onChangeSize={ handleAddingParam('size') }
						onChangeDate={ handleAddingParam('date') }
						onReset={ resetFilters }
						sex={ sex }
						mode={ mode }
						sizes={ sizes }
						brand={ brand }
						size={ size }
						brandsIDs={ brandsIDs }
						priceFrom={ priceFromIn }
						priceTo={ priceToIn }
						date={ date }
					/>
				}
				content={
					<ItemsContainer mode={ +mode } data={ +date } count={ filter } sex={ sex } brand={ brand } size={ size } category={ categoryID } price={ { from: +priceFromValue, to: +priceToValue } } page={ +page ?? 1 } className={ style.itemGroup } />
				}
				currentPage={ +page }
				handleChangePage={ handleChangePage }
				pageCount={ pageCount }
			/>
		</MainTemplate>
	);
});
