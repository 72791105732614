import style from './style.module.scss';
import { Icon } from '../icon/icon';
import { ReactComponent as PromptSVG } from '../../images/prompt.svg';

interface PromtProps {
  text: string
}

export const Promt = ({ text }: PromtProps) => {
	return (
		<div className={ style.prompt }>
			<Icon image={ PromptSVG } size='small' className={ style.promptIcon } />
			{ text }
		</div>
	);
};
