import style from './style.module.scss';
import { Typography } from '../../components/typography/typography';
import { Nav } from '../../components/navigation/nav';
import { Button } from '../../components/button/button';
import { Tabs } from '../../components/tabs/tabs';
import { useState } from 'react';
import { Tab } from '../../components/tab/tab';
import { UsersAdminTable } from '../../components/admin/Tabs/usersAdminTable';
import { ReviewsAdminTable } from '../../components/admin/Tabs/reviewsAdminTable';
import { OrdersAdminTable } from '../../components/admin/Tabs/ordersAdminTable';

export const Admin = (): JSX.Element => {
	const [tab, setTab] = useState(1);

	return (
		<main className={ style.admin }>
			<Typography type='h3' tag='h1'>Админ-панель</Typography>
			<div className={ style.dashboard }>
				<Nav className={ style.nav } orientation='horizontal'>
					<Button onClick={ () => setTab(1) }>Пользователи</Button>
					<Button onClick={ () => setTab(2) }>Заказы</Button>
					<Button onClick={ () => setTab(3) }>Отзывы</Button>
				</Nav>
				<div className={ style.workspace }>
					<Tabs currentValue={ tab }>
						<Tab value={ 1 }>
							<UsersAdminTable />
						</Tab>
						<Tab value={ 2 }>
							<OrdersAdminTable />
						</Tab>
						<Tab value={ 3 }>
							<ReviewsAdminTable />
						</Tab>
					</Tabs>
				</div>
			</div>
		</main>
	);
};
