import { request } from '../request';

interface ISearchFetchRequest {
	query: string,
	count?: number,
	offset?: number,
	mode?: number,
	emptyAnswer?: boolean,
	hash?:string
}

export const searchFetch = (query: ISearchFetchRequest) => {
	return request({ url: '/search', params: query });
};

export const reverseImageSearch = (query: ISearchFetchRequest, image:File) => {
	if (!image) {
		return searchFetch(query);
	}
	const formData = new FormData();
	formData.append('image', image!, `targetImage.${image.type && image.type.split('/')[1] || 'jpg'}`);
	const options = {
		method: 'POST' as 'GET' | 'POST' | 'PUT' | 'DELETE',
		url: '/search',
		params: query,
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}
	return request(options);
};