import style from '../button/style.module.scss';
import { GeneralLinkProps, Link } from '../link/link';
import cs from 'classnames';
import { ButtonTheme, generalButtonThemes } from '../button/button';

interface ButtonLinkProps extends GeneralLinkProps {
    href: string,
    theme?: ButtonTheme,
}

export const ButtonLink = ({ href, type, children, className, theme }: ButtonLinkProps) => {
	return <Link className={ cs(className, generalButtonThemes[theme!], style.button) } href={ href } type={ type } theme='default'>{ children }</Link>;
};

ButtonLink.defaultProps = {
	theme: 'primary',
	size: 'medium'
};