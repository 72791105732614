import style from './style.module.scss';
import { Typography } from '../typography/typography';
import { ReactComponent as ShopSVG } from '../../images/shop.svg';
import cs from 'classnames';

interface ShopInfoProps {
  address: string | undefined,
  mobile: string | undefined,
  workTime: string | undefined,
  className?: string
}

export const ShopInfo = ({ address, mobile, workTime, className }: ShopInfoProps): JSX.Element => {
	return (
		<div className={ cs(style.shopInfo, className) }>
			<ShopSVG className={ style.icon }/>
			<div className={ style.infoGroup }>
				<Typography type='body' className={ style.infoBlock }>{ 'Адрес: ' + address }</Typography>
				<Typography type='body' className={ style.infoBlock }>{ 'Телефон: ' + mobile }</Typography>
				<Typography type='body' className={ style.infoBlock }>{ 'Время работы: ' + workTime }</Typography>
			</div>
		</div>
	);
};

ShopInfo.defaultProps = {
	className: null
};
