import { useRecoilState } from 'recoil';
import {
	isLoggedAtom, isMobileAtom,
	itemBrandsAtom,
	itemCategoriesAtom,
	itemSizesAtom, userAtom, userBasketAtom
} from '../../recoil/atoms';
import { useCallback, useEffect } from 'react';
import { useBasket } from '../../lib/utils/hooks/useBasket';
import { meFetch } from '../../lib/api';
import { useDetectMobile } from '../../lib/utils/hooks/useDetectMobile';
import { brandsFetch, sizesFetch, categoriesFetch } from '../../lib/api';
import { setAuthToken } from '../../lib/api';
import { getBasket, getToken, setBasket } from '../../lib/storage';
import { normalizeMobile } from '../../lib/utils/normilizeMobile';
import { cartFetch, cartPut } from '../../lib/api/rest/cart';

interface ApiInitProps {
  children: JSX.Element | JSX.Element[]
}

export const APIInit = ({ children }: ApiInitProps): JSX.Element => {
	const [isLoggedUser, setLoggedUser] = useRecoilState(isLoggedAtom);
	const [, setMobile] = useRecoilState(isMobileAtom);
	const [, setBrands] = useRecoilState(itemBrandsAtom);
	const [, setSizes] = useRecoilState(itemSizesAtom);
	const [, setCategories] = useRecoilState(itemCategoriesAtom);
	const [, setUserData] = useRecoilState(userAtom);
	const [, setUserBasket] = useRecoilState(userBasketAtom);
	const isMobileDevice = useDetectMobile();
	const { basket: getRootBasket, addMultiple } = useBasket();

	useEffect(() => {
		setMobile(isMobileDevice);
	}, [isMobileDevice]);

	useEffect(() => {
		if (getRootBasket) {
			const serializedBasket = {
				cart: getRootBasket,
				modified_date: new Date()
			};

			setBasket(serializedBasket).then(() => isLoggedUser && cartPut(serializedBasket));
		}
	}, [getRootBasket]);

	const addBasketFromLocalStorage = useCallback(() => {
		getBasket().then((basket) => {
			if (basket?.cart) setUserBasket(basket?.cart);
		});
	}, []);

	useEffect(() => {
		brandsFetch().then((res) => setBrands(res?.data));
		sizesFetch().then((res) => setSizes(res?.data));
		categoriesFetch().then((res) => setCategories(res?.data?.categories));

		getToken().then((jwt) => {
			if (jwt) {
				// Since some requests require a token, the first step is to pass it to the config

				setAuthToken(jwt);
				setLoggedUser(!!jwt);
			} else addBasketFromLocalStorage();
		});
	}, []);

	useEffect(() => {
		if (isLoggedUser) {
			meFetch().then((res) => {
				if (!res.data) return null;

				const { user: { email, phone, orderAutocomplete: { flat, street, house } } } = res.data || {};

				setUserData({
					mobile: normalizeMobile(phone),
					email, house, street, flat
				});
			});

			cartFetch().then((basket) => {
				getBasket().then((localBasket) => {
					const { cart, modified_date } = basket.data || {};

					if (!localBasket?.modified_date || new Date(modified_date) > new Date(localBasket.modified_date)) {
						addMultiple(cart);
					} else addBasketFromLocalStorage();
				});
			});
		}
	}, [isLoggedUser]);

	return <>{ children }</>;
};
