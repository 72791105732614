import style from './style.module.scss';
import { createPortal } from 'react-dom';
import { Typography } from '../typography/typography';
import { ReactComponent as CloseSVG } from '../../images/close.svg';
import { IconButton } from '../iconButton/iconButton';
import { useSpring, animated } from 'react-spring';
import cs from 'classnames';
import { useEffect } from 'react';

interface ModalProps {
  title: string | undefined,
  subtitle: string | undefined,
  visible: boolean,
  onClose?: () => void
  children: JSX.Element | JSX.Element[],
  className?: string
}

const ModalWindow = ({ title, subtitle, visible, children, onClose, className }: ModalProps): JSX.Element => {
	useEffect(() => {
		if (visible) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [visible]);

	useEffect(() => {
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);

	const overlayAnim = useSpring({
		background: visible ? 'rgba(00, 00, 00, 0.3)' : 'rgba(00, 00, 00, 0)',
		display: visible ? 'block' : 'none'
	});

	const modalAnim = useSpring({
		transform: visible ? 'translate(-50%, -50%)' : 'translate(-50%, -100%)'
	});

	return (
		<animated.div style={ overlayAnim } className={ style.overlay }>
			<animated.div className={ cs(style.modal, className) } style={ modalAnim }>
				<div className={ style.head }>
					<div className={ style.principalText }>
						{ title && <Typography type='h4' tag='h2' className={ style.title }>{ title }</Typography> }
						{ subtitle && <Typography type='h5' tag='h3'>{ subtitle }</Typography> }
					</div>
					<IconButton iconClassName={ style.closeIcon } onClick={ onClose } className={ style.closeButton } size='small' icon={ CloseSVG } />
				</div>
				{ children }
			</animated.div>
		</animated.div>
	);
};

export const Modal = (props: ModalProps): JSX.Element => {
	return createPortal(ModalWindow(props), document.body);
};

ModalWindow.defaultProps = {
	onClose: null,
	className: undefined
};
