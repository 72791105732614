import style from './style.module.scss';
import cs from 'classnames';

interface SlideContentProps {
  bgImage?: string,
  className?: string,
  subtitle?: JSX.Element,
  title?: JSX.Element,
  body?: JSX.Element
}

export const SlideContent = ({ bgImage, className, subtitle, title, body }: SlideContentProps): JSX.Element => {
	const slideContentStyle = {
		background: `url(${bgImage}) no-repeat center/cover`
	};

	return (
		<div className={ cs(style.slideContent, className) } style={ slideContentStyle }>
			{ subtitle }
			{ title }
			{ body }
		</div>
	);
};

SlideContent.defaultProps = {
	bgImage: null,
	className: null,
	title: null,
	subtitle: null,
	body: null
};
