import { Icon } from '../icon/icon';

import cn from 'classnames';
import style from './style.module.scss';
import { Typography } from '../typography/typography';
import { Link } from '../link/link';

interface NavLinkProps {
  to: string,
  label: string,
  icon?: any,
  className?: string,
  onClick?: () => void
}

export const NavLink = ({ to, label, icon, className, onClick }: NavLinkProps): JSX.Element => {
	return (
		<Link type='inner' onClick={ onClick } theme='default' className={ cn(style.navLink, className) } href={ to }>
			{ icon && <Icon className={ style.icon } image={ icon } size='small'/> }
			<Typography type='body' tag='span'>{ label }</Typography>
		</Link>
	);
};

NavLink.defaultProps = {
	icon: null,
	className: null,
	onClick: null
};
