import style from './style.module.scss';
import { Table } from '../../table/table';
import { Pagination } from '../../pagination/pagination';
import { ToggleVisibility } from '../../toggleVisibility/toggleVisibility';

interface AdminTableProps {
  data: any,
  columns: string[],
  page?: number,
  pageCount?: number,
  handleChangePage?: (number: number) => void
}

export const AdminTable = ({ data, page, handleChangePage, pageCount, columns }: AdminTableProps) => {
	return (
		<div className={ style.table }>
			<Table columns={ columns } data={ data } />
			<ToggleVisibility visible={ page !== 0 }>
				<Pagination className={ style.pagination } onChange={ handleChangePage } currentPage={ page } pageCount={ pageCount as number }/>
			</ToggleVisibility>
		</div>
	);
};

AdminTable.defaultProps = {
	page: 0,
	pageCount: 0,
	handleChangePage: undefined
};
