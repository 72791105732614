import style from './style.module.scss';
import { Review } from '../review/review';
import cs from 'classnames';

interface IReview {
  firstname: string,
  lastname: string,
  text: string,
  rating: number
}

interface ReviewGroupProps {
  className?: string,
  reviews: IReview[]
}

export const ReviewGroup = ({ className, reviews }: ReviewGroupProps) => {
	let reviewID = 0;

	return (
		<div className={ cs(style.reviewsGroup, className) }>
			{ reviews?.map((review) => {
				const { firstname, lastname, text, rating } = review;

				reviewID++;

				return <Review className={ style.review } key={ reviewID } author={ `${firstname} ${lastname}` } rating={ rating } review={ text } textLimit={ 100 } />;
			}) }
		</div>
	);
};

ReviewGroup.defaultProps = {
	className: null
};
